import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'


const Contacts = () => {
    let history = useNavigate();
    const[data, setData] = useState({
        nom:"",
        email:"",
        objet:"",
        telephone:"",
        message:""
    })
    const handleChange=(e)=>{
        setData({...data, [e.target.name]: e.target.value});
    }
    const submitForm=(e)=>{
        e.preventDefault();
        const sendData ={
            nom:data.nom,
            email:data.email,
            objet:data.objet,
            telephone:data.telephone,
            message:data.message
        }
        console.log(sendData);

        axios.post('http://localhost/php-react/registre-contact-php-simple/insert.php',sendData)
        .then((result)=>{
            if(result.data.Status === 'Invalid')
         alert('Invalid User');
        else{
            history('/dashboard')
        }
        })
      }
    return(
        <div>
              <div className='w-full xl:px-8 flex justify-center items-center flex-col text-lg font-thin place-items-start gap-2'>
                <div className='w-[100%] pb-8 md:pb-16 lg:pb-20 xl:px-8 md:mt-2 mt-1 bg-white text-black rounded flex justify-start items-center flex-col'>
                    <div className='lg:text-2xl md:text-xl text-sm font-bold lg:py-20 md:py-12 py-10'>Nous contacter</div>
                    <form  onSubmit={submitForm} className='w-full md:w-[700px] flex justify-center items-center flex-col'>
                        <div className='w-full md:px-0 px-8 grid grid-cols-1 md:grid-cols-2 grid-row-2 gap-y-4 md:gap-y-8 md:gap-x-8'>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="nom" name="nom" placeholder='Prenom & Nom' 
                                onChange={handleChange} value={data.nom} />
                            </div>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="email" name="email" placeholder='Email' 
                                onChange={handleChange} value={data.email} />
                            </div>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="objet" name="objet" placeholder='Objet' 
                                onChange={handleChange} value={data.objet} />
                            </div>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="telephone" name="telephone"  placeholder='Téléphone' 
                                onChange={handleChange} value={data.telephone} />
                            </div>
                        </div>
                        <div className='w-full md:px-0 px-8 mt-4 md:mt-8 flex justify-center items-center rounded'>
                            <textarea className='w-full h-36 max-h-60 resize-none text-start py-4 rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="message" name="message" placeholder='Message . . .'
                            onChange={handleChange} value={data.message} />
                        </div>
                        <div className='md:px-0 px-8 w-full md:w-fit'>
                            <input type="submit" name="submit" className='w-full md:w-fit h-12 px-10 my-4 md:my-8 rounded bg-primary text-white font-normal flex justify-center items-center duration-150 hover:px-10 hover:bg-secondary hover:text-white shadow-lg shadow-gray-100' value='Envoyer'/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contacts;