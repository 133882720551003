import img1 from '../../../res/04/IMG-20210430-WA0055.jpg';
import img2 from '../../../res/04/IMG-20210430-WA0056.jpg';
import img3 from '../../../res/04/IMG-20210430-WA0057.jpg';
import img5 from '../../../res/04/IMG-20210430-WA0051.jpg';
import img4 from '../../../res/04/IMG-20210430-WA0058.jpg';
import img6 from '../../../res/04/IMG-20210430-WA0052.jpg';
import img7 from '../../../res/04/IMG-20210430-WA0053.jpg';
import img8 from '../../../res/04/IMG-20210430-WA0050.jpg';
import img9 from '../../../res/04/IMG-20210623-WA0022.jpg';
import img10 from '../../../res/03/IMG_2803.jpg';
import img11 from '../../../res/04/IMG_5053.jpg';
import img12 from '../../../res/04/IMG-20210623-WA0052.jpg';
import img13 from '../../../res/04/IMG-20201113-WA0026.jpg';
import img14 from '../../../res/04/IMG_4605.jpg';
import img15 from '../../../res/04/IMG_4599.jpg';
import img16 from '../../../res/04/IMG_5094.jpg';
 

export const Files2 =[
    {
        index : "1",
        filetype : "img",
        filepath : img1
    },
    {
        index : "2",
        filetype : "img",
        filepath : img2
    },
    {
        index : "3",
        filetype : "img",
        filepath : img3
    },
    {
        index : "4",
        filetype : "img",
        filepath : img4
    },
    {
        index : "5",
        filetype : "img",
        filepath : img5
    },
    {
        index : "6",
        filetype : "img",
        filepath : img6
    },
    {
        index : "7",
        filetype : "img",
        filepath : img7
    },
    {
        index : "8",
        filetype : "img",
        filepath : img8
    },
    {
        index : "9",
        filetype : "img",
        filepath : img9
    },
    {
        index : "10",
        filetype : "img",
        filepath : img10
    },
    {
        index : "11",
        filetype : "img",
        filepath : img11
    },
    {
        index : "12",
        filetype : "img",
        filepath : img12
    },
    {
        index : "13",
        filetype : "img",
        filepath : img13
    },
    {
        index : "14",
        filetype : "img",
        filepath : img14
    },
    {
        index : "15",
        filetype : "img",
        filepath : img15
    },
    {
        index : "16",
        filetype : "img",
        filepath : img16
    },
]