import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/04/img/receptionniste gouvernante.jpg';
import imageServiceTechnique from '../../../../res/formations/MANAGEMENT HOTELIER_page-0001.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelerec from './accelerec';
import Comple from "../comple";

const Receptionniste = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Receptionniste - Gouvernante"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className=" md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
           
          Les objectifs d'une formation en réceptionniste gouvernante sont de préparer les étudiants à assumer des rôles clés dans le secteur de l'hôtellerie et de l'hébergement. Voici les principaux objectifs de cette formation :<br />
          <span class="text-semi-bold">-</span> Service à la clientèle : Développer des compétences exceptionnelles en service à la clientèle pour garantir la satisfaction des clients et les fidéliser. Cela inclut la capacité à répondre aux besoins des clients, à gérer les plaintes et à offrir un service de qualité.<br />
          <span class="text-semi-bold">-</span> Compétences opérationnelles : Anciens étudiants aux tâches opérationnelles de la réception et de la gouvernante, y compris l'enregistrement des clients, la gestion des réservations, la gestion des chambres, le nettoyage, la maintenance et la gestion des stocks.
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         Une fois que vous suivrez une formation en réceptionniste gouvernante, vous aurez accès à divers débouchés professionnels dans l'industrie de l'hôtellerie, de l'hébergement et du tourisme. Voici quelques-uns des débouchés professionnels potentiels auxquels vous pourriez accéder :<br />
         1- Réceptionniste d'hôtel <br />
         2- Gouvernante d'hôtel
             </p>
             <a href={document} download className='absolute mt-8 ml-64  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Accelerec />
    <Comple />
    <Footer />
</div>
    
  )
 
}

export default Receptionniste