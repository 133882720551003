import React from 'react'
import Header from '../../elements/header';
import Menu from '../../elements/menu/menu';
import { FaClock, FaFacebookMessenger, FaFacebookSquare, FaLinkedin,  FaPhone, FaShieldAlt, FaTiktok, FaStar,  FaYoutubeSquare } from 'react-icons/fa';
import Institut from '../../elements/institut/institut';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <div>
        <Header/>
        <Menu />  
        <div className='w-6/6  xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center'>
                <div className='w-full  md:h-25 md:mt-1 mt-6  bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                        <h2 className="lg:text-2xl mr-4 mt-8 md:text-xl text-sm flex justify-center items-center font-bold"> Admission </h2>
                        <h2 className='lg:text-[0.9rem] mr-4  md:text-[0.9rem] mt-12 text-sm flex justify-center items-center font-normal'>Le Groupe Mawa a mis en place une plateforme en ligne pour vous faciliter les candidatures aux formations</h2>
                        <Link className='' to="/contact/preinscription"><div className='h-10 w-32 mt-8 ml-60 rounded bg-secondary text-white font-bold text-[0.9rem] font-black flex justify-center items-center duration-150  hover:bg-primary hover:px-10 hover:text-white shadow-gray-100'>Candidature</div></Link>
              </div>
              </div>
        <div className='w-sreen xl:px-8 mt-6 mb-8 flex justify-center items-center flex-col font-normal text-xs snap-center'>
                <div className='w-full md:h-fit md:mt-2 mt-1  bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                   <contact className='w-full  xl:px-8 flex justify-center items-center flex-col font-medium xl:text-lg'>
                <div className='w-[100%] md:mt-2 mt-1 bg-primary text-black xl:rounded-t'>
                    <div className='w-full md:h-fit md:py-8  grid grid-cols-2 md:grid-cols-4 text-white text-[0.6rem] md:text-[0.7rem] lg:text-xs xl:text-sm font-light place-items-start gap-2'>
                        <div className='w-full h-52 md:h-auto flex md:pl-20 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Groupe MAWA</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaClock size={9}/><span className='ml-2 md:ml-4'>Lundi au Samedi de 8h à 18h30</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 77 656 51 96 & +221 78 135 22 57</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaFacebookMessenger size={9}/><span className='ml-2 md:ml-4'>infos@complexemawa.com</span></a>
                        </div>
                        <div className='w-full h-52 md:h-auto flex md:pl-12 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Sites du Groupe</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Keur Massar</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Diaxaye</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Castor</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Rond Point JVC</span></a>
                        </div>
                        <div className='w-full h-52 md:h-auto flex md:pl-12 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Nos Contacts</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaShieldAlt size={9}/><span className='ml-2 md:ml-4'>+221 78 135 22 57 &nbsp;</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaShieldAlt size={9}/><span className='ml-2 md:ml-4'>+221 77 169 35 82 &nbsp;</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaShieldAlt size={9}/><span className='ml-2 md:ml-4'>+221 70 470 67 26 &nbsp;</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaShieldAlt size={9}/><span className='ml-2 md:ml-4'>+221 70 203 91 16 &nbsp;</span></a>
                        </div>
                        <div className='w-full h-52 md:h-auto flex md:pl-12 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Nous suivre</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaFacebookSquare/><span className='ml-2 md:ml-4'>Facebook</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaLinkedin/><span className='ml-2 md:ml-4'>LinkedIn</span></a>
                            <a className=' py-1 md:py-2 flex justify-center items-center' href=""><FaYoutubeSquare/><span className='ml-2 md:ml-4'>Youtube</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaTiktok/><span className='ml-2 md:ml-4'>TikTok</span></a>
                        </div>
                    </div>
                    <div className='w-full h-[50px] bg-secondary  flex justify-center items-center text-white text-[0.6rem] md:text-xs'><p> © 2023 Groupe Mawa. Design by abg</p></div>
                </div>
            </contact>
            <Institut />
                    </div> 
                        <div className='xl:w-[400px] lg:w-[600] md:w-[400px] w-full overflow-hidden md:px-0 px-2 flex justify-center items-center md:items-start flex-col '>
                        </div>
                        
                    </div>
                    
                </div>
         
  )
}

export default Contact