export const datafor = [
    {
      Niveau : "Niveau étude : Tous les niveaux",
      Durée: "12 mois",
      Inscription: "Inscription : 50000 FCFA",
      Mensualité:'Mensualité : 20000 FCFA (30000 FCFA à Sacré-Cœur)'
    },
    {
      Niveau : "Niveau étude : Tous les niveaux",
      Durée: "6 mois",
      Inscription: "Inscription : 100000 FCFA",
      Mensualité:' Mensualité : 50000 FCFA (60000 FCFA à Sacré-Cœur)'
      
    },
 
];