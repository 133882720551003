import React from 'react';

const Projects = () => {
    return(
        <div>
            <div className='w-full xl:px-8 flex justify-center items-center flex-col font-medium text-xs md:text-sm lg:text-lg relative'>
                <div className='w-full h-[450px] md:h-[300px] md:mt-2 mt-1 xl:rounded overflow-hidden bg-sante bg-cover'></div>
                <div className='w-full md:w-auto h-[450px] md:h-[300px] p-4 xl:mx-8 md:p-8 mt-1 md:mt-2 text-black xl:rounded flex justify-center md:justify-around items-center flex-col md:flex-row absolute top-0 left-0 right-0 backdrop-brightness-50 backdrop-blur-lg md:backdrop-blur-lg '>
                    <a href="" className='w-full md:w-auto md:mb-0 mb-4 flex justify-center'><div className='h-28 md:h-20 w-full max-w-[350px] md:w-auto px-10 bg-white md:hover:scale-110 hover:bg-primary hover:text-white duration-300 rounded flex justify-center items-center'>Projets d'étude</div></a>
                    <a href="" className='w-full md:w-auto md:mb-0 mb-4 flex justify-center'><div className='h-28 md:h-20 w-full max-w-[350px] md:w-auto px-10 bg-white md:hover:scale-110 hover:bg-primary hover:text-white duration-300 rounded flex justify-center items-center'>Projets en cours</div></a>
                    <a href="" className='w-full md:w-auto md:mb-0 mb-4 flex justify-center'><div className='h-28 md:h-20 w-full max-w-[350px] md:w-auto px-10 bg-white md:hover:scale-110 hover:bg-primary hover:text-white duration-300 rounded flex justify-center items-center'>Projets réalisés</div></a>
                </div>
            </div>
        </div>
    )
}

export default Projects;