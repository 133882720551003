import React from 'react';
import { Link } from 'react-router-dom';
import imageServiceTechnique from '../../../res/8mars/IMG-20230309-WA0058.jpg'
import imageServiceTechnique1 from '../../../res/02/10ans.PNG'
import imageServiceTechnique2 from '../../../res/images/IMG_9443-300x200.jpg'
import imageServiceTechnique5 from '../../../res/03/castor.png'
import imageServiceTechnique6 from '../../../res/03/IMG_9455-2048x1365.jpg'
import imageServiceTechnique8 from '../../../res/03/IMG_2803-1024x683.jpg'
import imageServiceTechnique7 from '../../../res/images/IMG_9443-300x200.jpg'
import imageServiceTechnique9 from '../../../res/03/20210928_135927-768x576.jpg'
import imageServiceTechnique10 from '../../../res/02/FB_IMG_16245409139711670.jpg'
const News = () => {
    return(
        <div>
            <div className='w-full xl:px-8 flex justify-center items-center flex-col text-lg font-thin place-items-start gap-2'>
                <div className='w-[100%] pb-8 md:pb-16 lg:pb-20 xl:px-8 md:mt-2 mt-1 bg-white text-black rounded flex justify-start items-center flex-col'>
                    <div className='lg:py-20 md:py-12 py-10 font-bold lg:text-2xl md:text-xl text-sm'> Toute l'actualité du Groupe Mawa </div>
                    <div className='w-full md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-3 md:grid-cols-2 md:gap-x-8 md:gap-y-8'>
                       <Link className='w-full flex justify-center px-4 pb-4 md:p-0' to="../actualite"><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-blank flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                        <img className='h-full group-hover:scale-50 duration-1000' src={imageServiceTechnique} alt="" />
                        <div className='absolute bottom-0 group-hover:h-full group-hover:rounded duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs lg:text-xs md:pb-1'>08 Mars 2023</p>
                                <p className='text-white text-xs md:text-sm lg:text-[0.9rem] font-semibold'>Le Goupe Mawa célébre la journée internationale des droits de la femme</p>
                            </div>
                        </div></Link>
                        <Link className='w-full flex justify-center px-4 pb-4 md:p-0' to="../concours"><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-blank flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                        <img className='h-full group-hover:scale-50 duration-1000' src={imageServiceTechnique2} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:rounded duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>05 Avril 2023</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Jeux de concours les Etoiles de Mawa</p>
                            </div>
                        </div></Link>
                        <Link className='w-full flex justify-center px-4 pb-4 md:p-0' to="../anniversaire"><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-blank flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                            <img className='h-full group-hover:scale-50 duration-1000' src={imageServiceTechnique1} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:rounded duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>08 Mars 2021</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Le Groupe Mawa fête ses 10 ans  </p>
                            </div>
                        </div></Link>
                        </div>
                        </div>
                        </div>
                        <div className='w-full xl:px-8 flex justify-center items-center flex-col text-lg font-thin'>
                            <div className='w-[100%] pb-8 md:pb-16 lg:pb-20 xl:px-8 md:mt-2 mt-1 bg-white text-black rounded flex justify-start items-center flex-col'>
                                <div className='lg:py-20 md:py-12 py-10 font-bold lg:text-2xl md:text-xl text-sm'> Evénements </div>
                                <div className='w-full md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-3 md:grid-cols-2 md:gap-x-8 md:gap-y-8'>
                                <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                                <img className='h-full duration-1000' src={imageServiceTechnique5} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>08 Novembre 2023</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Rentrée des classes au différents instituts du Groupe Mawa</p>
                            </div>
                        </div></a>
                        <a href="../Travaux" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                            <img className='h-full duration-1000' src={imageServiceTechnique6} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>19 Octobre 2022</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Le Groupe Mawa organise un concours pour les jeunes talents</p>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                              <img className='h-full duration-1000' src={imageServiceTechnique7} alt="" />
                               <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>Novembre 2023</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Examen de fin d'étude des éléves de couture du Complexe Mawa</p>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                                 <img className='h-full duration-1000' src={imageServiceTechnique8} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>Novembre 2023</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Examen de fin d'étude des éléves de coiffure du Complexe Mawa</p>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                                 <img className='h-full duration-1000' src={imageServiceTechnique9} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>Novembre 2023</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Examen de fin d'étude des éléves de Restauration du Complexe Mawa</p>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg'>
                                <img className='h-full duration-1000' src={imageServiceTechnique10} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>02 Décembre 2023</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Cérémonie de remise de diplôme au sein du Groupe Mawa</p>
                            </div>
                        </div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News;