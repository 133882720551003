
import img1 from '../../../res/images/IMG_0081-300x200.jpg';
import img2 from '../../../res/images/IMG_0084-300x200.jpg';
import img3 from '../../../res/images/IMG_0085-300x200.jpg';
import img4 from '../../../res/03/IMG_0340.jpg';
import img5 from '../../../res/03/IMG_0274.jpg';
import img6 from '../../../res/03/IMG_0260.jpg';
import img7 from '../../../res/03/IMG_0093-300x200.jpg';
import img8 from '../../../res/03/IMG_0086-1.jpg';
import img9 from '../../../res/02/IMG-20220924-WA0042.jpg';
import img10 from '../../../res/02/IMG-20220924-WA0040.jpg';
import img11 from '../../../res/02/IMG-20220924-WA0044.jpg';
import img12 from '../../../res/02/IMG-20220924-WA0066.jpg';

export const Files =[
    {
        index : "1",
        filetype : "img",
        filepath : img1
    },
    {
        index : "2",
        filetype : "img",
        filepath : img2
    },
    {
        index : "3",
        filetype : "img",
        filepath : img3
    },
    {
        index : "4",
        filetype : "img",
        filepath : img4
    },
    {
        index : "5",
        filetype : "img",
        filepath : img5
    },
    {
        index : "6",
        filetype : "img",
        filepath : img6
    },
    {
        index : "7",
        filetype : "img",
        filepath : img7
    },
    {
        index : "8",
        filetype : "img",
        filepath : img8
    },
    {
        index : "9",
        filetype : "img",
        filepath : img9
    },
    {
        index : "10",
        filetype : "img",
        filepath : img10
    },
    {
        index : "11",
        filetype : "img",
        filepath : img11
    },
    {
        index : "12",
        filetype : "img",
        filepath : img12
    },
]