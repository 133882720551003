
import img from '../../../res/images/blank.jpg';
export const Files2 =[
    {
        index : "1",
        filetype : "img",
        filepath : img
    },
    {
        index : "2",
        filetype : "img",
        filepath : img
    },
    {
        index : "3",
        filetype : "video",
        filepath : "https://www.youtube.com/embed/ybEtf0Mwb6U"
    },
    {
        index : "4",
        filetype : "img",
        filepath : img
    },
    {
        index : "5",
        filetype : "video",
        filepath : "https://www.youtube.com/embed/WTPComBPqsw"
    },
]