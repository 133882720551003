import React from 'react';
import imageServiceTechnique from '../../../res/restaurant/IMG_1520.JPG'


const About = () => {
    
    return(
        <div>                 
        
        <div className='w-6/7 xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
        <div className='w-full md:h-full md:mt-2 mt-1 bg-white text-black  pb-10 md:pb-28'>
        <div className='lg:py-10 md:py-8 py'>
        <h1 className='m-50 mb-10 text-red-500  text-red-500 lg:text-xl md:text-xl text-sm text-center font-bold '>MW RESTAURANT TRAITEUR</h1>
        </div>
           <div className='w-6/6 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
        <img className="w-1/2 h-64" src= { imageServiceTechnique } alt="" />   
    <div className='xl:w-[500px] lg:w-[100] md:w-[100px] w-full overflow-hidden md:px-0 px-2 flex justify-center items-center md:items-start flex-col '>
       
         <p class="mt-8 text-[0.9rem] text-blue-600 text-semi-bold md:text-[0.9rem] text-justify font-medium leading-5 md:leading-7">Nous sommes impatients de vous accueillir au restaurant "MW RESTAURANT ET TRAITEUR" et de vous faire découvrir un voyage culinaire exceptionnel. Venez nous rendre visite et laissez-vous séduire par notre cuisine délicieuse, notre ambiance chaleureuse et notre service de qualité. Votre satisfaction est notre priorité, et nous sommes déterminés à vous offrir une expérience inoubliable à chaque visite.  </p>
  
           </div>
    </div>
         
              
     </div>
            
        </div>
        </div>
    )
}

export default About;