import React from 'react'
import Header from '../../elements/header';
import Footer from '../../elements/footer';
import Menu from '../../elements/menu/menu';
import Page_info from '../../elements/page_info';
import Partage from '../../elements/partage';
import bg from '../../../res/images/module.jpg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const Contact = () => {
    let history = useNavigate();
    const[data, setData] = useState({
        prenom:"",
        nom:"",
        telephone:"",
        nationalite:"",
        diplome:"",
        filiere:""
    })
    const handleChange=(e)=>{
        setData({...data, [e.target.name]: e.target.value});
    }
    const submitForm=(e)=>{
        e.preventDefault();
        const sendData ={
            prenom:data.prenom,
            nom:data.nom,
            telephone:data.telephone,
            nationalite:data.nationalite,
            diplome:data.diplome,
            filiere:data.filiere
        }
        console.log(sendData);

        axios.post('http://localhost/php-react/registre-contact-php-simple/insertion.php',sendData)
        .then((result)=>{
            if(result.data.Status === 'Invalid')
         alert('Invalid User');
        else{
            history('/contact/preinscription')
        }
        })
      }
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Contact"} link={"Contact > Préinscription"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[30%]'}/>
        <div className='w-sreen xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center'>
                <div className='w-full md:h-fit md:mt-2 mt-1 bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                    <div className='lg:py-20 md:py-12 py-10'>
                        <h2 className="lg:text-2xl md:text-xl text-sm text-center font-bold"> Formulaire de pré-inscription </h2>
                    </div>
                    <div className='w-full flex h-full justify-center items-center md:items-start md:flex-row flex-col md:px-0 px-12'>
                   <div className='md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16'>
                    </div> 
                        <div className='xl:w-[400px] lg:w-[600] md:w-[400px] w-full overflow-hidden md:px-0 px-2 flex justify-center items-center md:items-start flex-col '>
                        <form onSubmit={submitForm} className='w-full md:w-[700px] flex justify-center items-center flex-col'>
                        <div className='w-full md:px-0 px-8 grid grid-cols-1 md:grid-cols-2 grid-row-2 gap-y-4 md:gap-y-8 md:gap-x-8'>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" name="prenom" placeholder='Prenom ' required onChange={handleChange} value={data.prenom}/>
                            </div><br />
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" name="nom" placeholder='Nom' required onChange={handleChange} value={data.nom}/>
                            </div><br />
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="tel" name="telephone"  placeholder='Téléphone' required  onChange={handleChange} value={data.telephone}/>
                            </div><br />
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <select className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4'type="text" name="nationalite" onChange={handleChange} value={data.nationalite} >
                                <option>Selectionnez votre nationalité:</option>
                                <option>Sénégalais</option>
                                <option>Malien</option>
                                <option>Guinéen</option>
                                <option>Benin</option>
                                <option>Ivoirien</option>
                                <option>Gabonais</option>
                                <option> Autre Nationalité</option>
                                </select>
                            </div><br />
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <select className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" name="diplome" onChange={handleChange} value={data.diplome}>
                                <option>Sélectionneez votre dernier diplôme</option>
                                <option>Sans Diplôme</option>
                                <option>CFEE</option>
                                <option>BFEM</option>
                                <option>BAC</option>
                                <option>BTS</option>
                                <option>lICENCE</option>
                                <option> MASTER</option>
                                </select>
                            </div><br />
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <select className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" name="filiere" onChange={handleChange} value={data.filiere}>
                                <option>Sélectionneez une filière</option>
                                <optgroup label="Formations à la carte">
                                <option>Coiffure et Esthétique</option>
                                <option>Couture et Stylisme</option>
                                <option>Restauration</option>
                                </optgroup>
                                <optgroup label="Formation initiale">
                                <option>Coiffure </option>
                                <option>Couture - Modéliste</option>
                                <option>Esthétique</option>
                                <option>Tourisme</option>
                                <option>Vallet-fille de chambre</option>
                                <option>Stylisme</option>
                                <option>Hotellerie - Restauration</option>
                                <option>Stylisme</option>
                                </optgroup>
                                </select>
                            </div><br />
                        </div>
                        <div className='md:px-0 px-8 w-full md:w-2/3'>
                            <input type="submit" className='w-full md:w-fit h-12 px-10 my-4 md:mt-8 rounded bg-blue-500 text-black font-black flex justify-center items-center duration-150 hover:px-10 hover:bg-primary hover:text-white shadow-lg shadow-gray-100' name='submit' value='Envoyer'/>
                        </div>
                    </form>
                        </div>
                    </div>
                </div>
            </div>
        <Partage />
        <Footer/>
    </div>
  )
}

export default Contact