
import React from 'react';
import { useState, useEffect } from "react";
import "./Slider.css";
import Slider from "react-slick";
import {sliderData} from './slider-data';
import Slider1 from "../../../res/restaurant/IMG_0818.JPG";
import Slider2 from "../../../res/restaurant/IMG_1428.jpg";
import Slider3 from "../../../res/restaurant/IMG-20230401-WA0036.jpg";




const Restaurant  = () => {
    
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideLength = sliderData.length;
    const autoScroll = true;
  
    let slideInterval;
    let intervalTime = 8000;
  
    const nextSlide = () => {
      setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
      console.log("next");
    };
  
    const prevSlide = () => {
      setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
      console.log("prev");
    };
  
    function auto() {
      slideInterval = setInterval(nextSlide, intervalTime);
    }
  
    useEffect(() => {
      setCurrentSlide(0);
    }, []);
  
    useEffect(() => {
      if (autoScroll) {
        auto();
      }
      return () => clearInterval(slideInterval);
    }, [currentSlide]);


      return(
        <div>
        <Slider>
        <div className='w-full flex justify-center items-center xl:px-8 text-[0.8rem] md:text-[1.0rem]'>
                <div className='w-full flex justify-center items-center flex-col relative pt-12 md:pt-0 shadow-sm shadow-gray-800/10'>
                    <div className='w-[100%] h-[200px] md:h-[350px] lg:h-[800px] relative overflow-hidden '>
                    {sliderData.map((slide, index) => {
                        return (
                            <div key={index+10} className='flex justify-center items-center'>
                                <div className={index === currentSlide ? "  w-full h-full" : "slide"} key={index}>
                                    {index === currentSlide && (<img src={slide.image === 1 ? (Slider1) : (slide.image === 2 ? (Slider2) : (slide.image === 3 && (Slider3)))} alt="slide" className="w-full object-fill xl:rounded-b"/>)}
                                </div>
                            </div>
                        );
                    })}
                    </div>
                    <div className='absolute w-full h-0 md:h-[50px] lg:h-[100px] bottom-0 mix- from-gray-800 to-gray-800/0'></div>
                    <div className='w-[100%] h-[200px] md:h-[350px] lg:h-[1200px] absolute left-0 top-12 md:top-0 text-black flex justify-between items-center flex-col xl:rounded-b'>
                        <div className='w-full h-full flex justify-between items-center'>
                            <div className='w-fit flex justify-center items-center flex-col'>
                                {sliderData.map((slide, index) => {
                                    return (
                                        <div key={index+10} className='flex justify-center items-center flex-col'>
                                                {index === currentSlide && (
                                                    <div className="flex justify-center items-center flex-col">
                                                        <h2 className='md:mb-8 mb-2 lg:text-4xl text-xl font-semibold text-white slide-up'>{slide.heading}</h2>
                                                        <p className='lg:mb-16 md:mb-8 mb-4 text-2xl md:text-sm lg:text-[50px] text-white w-[90%] md:w-[70%] xl:leading-10 md:leading-9 leading-4 slide-down text-center'>{slide.description}</p>
                                                        <a className='text-black' href=""><div className='md:h-12 h-8 md:px-8 px-4 rounded-bl-lg bg-secondary text-white flex justify-center items-center duration-150 hover:px-10 hover:bg-primary hover:text-white'>Plus de détails</div></a>
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </Slider>
          </div> 
           
          
    );
  }

export default Restaurant;