import React from 'react';
import './App.css';
import './index.css';
import { Route, Routes} from "react-router-dom"
import Forbiden from './forbidenUrl';
import About from './components/pages/ecoles/about';
import Travaux from './Travaux';
import Ecoles from './components/pages/ecoles/ecoles/ecoles';
import Accueil1 from './components/pages/accueil/accueil/accueil';
import Actualite from './components/pages/actualite/actualite';
import Concours from './components/pages/actualite/concours';
import Anniversaire from './components/pages/actualite/anniversaire';
import Institut from './components/elements/institut/institut';
import Preinscription from './components/pages/contact/preinscription';
import Contact from './components/pages/contact/contact';
import Galerie from './components/pages/galerie/galerie';
import GalerieDetails from './components/pages/galerie/galerieDetails';
import GalerieDetails1 from './components/pages/galerie/galerieDetails1';
import GalerieDetails2 from './components/pages/galerie/galerieDetails2';
import Carte from './components/pages/formations/carte/carte';
import CarteDetails from './components/pages/formations/carte/carteDetails';
import Coiffure from './components/pages/formations/coiffure/coiffure';
import Couture from './components/pages/formations/couture/couture';
import CoutureDetails from './components/pages/formations/couture/coutureDetails';
import Stylisme from './components/pages/formations/stylisme/stylisme';
import Tourisme from './components/pages/formations/tourisme/tourisme';
import Chambre from './components/pages/formations/chambre/chambre';
import Receptionniste from './components/pages/formations/receptionniste/receptionniste';
import Esthetique from './components/pages/formations/esthetique/esthetique';
import Gestion from './components/pages/formations/gestion/gestion';
import Hotel  from './components/pages/formations/hotel/hotel';
import Accueil from './components/pages/formations/accueil/accueil';
import AccueilDetails from './components/pages/formations/accueil/accueilDetails';
import Media from './components/pages/media/media';
import MediaDetails from './components/pages/media/mediaDetails';
import MediaDetails1 from './components/pages/media/mediaDetails1';
import MediaDetails2 from './components/pages/media/mediaDetails2';


function App() {
  return (
    <>
      <Routes>
      <Route path="/" element={<Accueil1/>}/>
      <Route path='/accueil/'>
      <Route path="index" element={<Accueil1/>}/>
      <Route path="accueil" element={<Accueil1/>}/>
      </Route>
        <Route path="/none/" element={<Travaux/>}/>
        <Route path='/presentation/'>
        <Route path="ecoles" element={<Ecoles/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='actualite' element={<Actualite/>}/>
        <Route path='concours' element={<Concours/>}/>
        <Route path='anniversaire' element={<Anniversaire/>}/>
        </Route>
        <Route path='about' element={<About/>}/>
        <Route path='/formations/'>
        <Route path="Travaux" element={<Travaux/>}/>
          <Route path='carte/'>
            <Route index element={<Carte/>}/>
            <Route path="details" element={<CarteDetails/>}/>  
          </Route>
          <Route path='couture/'>
            <Route index element={<Couture/>}/>
            <Route path="details" element={<CoutureDetails/>}/>  
          </Route>
          <Route path='coiffure/'>
            <Route index element={<Coiffure/>}/>
            <Route path="details" element={<CoutureDetails/>}/>  
          </Route>
          <Route path='stylisme/'>
          <Route index element={<Stylisme/>}/>
            <Route path="details" element={<CoutureDetails/>}/>  
          </Route>
          <Route path='tourisme/'>
          <Route index element={<Tourisme/>}/>
            <Route path="details" element={<CoutureDetails/>}/>  
          </Route>
          <Route path='receptionniste/'>
          <Route index element={<Receptionniste/>}/>  
          </Route>
          <Route path='chambre/'>
          <Route index element={<Chambre/>}/>  
          </Route>
          <Route path='esthetique/'>
          <Route index element={<Esthetique/>}/>  
          </Route>
          <Route path='chambre/'>
          <Route index element={<Chambre/>}/>  
          </Route>
          <Route path='gestion/'>
          <Route index element={<Gestion/>}/>  
          </Route>
          <Route path='hotel/'>
          <Route index element={<Hotel/>}/>  
          </Route>
          <Route path='accueil/'>
          <Route index element={<Accueil/>}/>
            <Route path="details" element={<AccueilDetails/>}/>  
          </Route>
        </Route>
        <Route path='/contact'>
          <Route path="preinscription" element={<Preinscription/>}/>
          <Route path="contact" element={<Contact/>}/>
        </Route>
        <Route path='/galerie'>
            <Route index element={<Galerie/>}/>
          <Route path="album" element={<GalerieDetails/>}/>
          <Route path="album1" element={<GalerieDetails1/>}/>
          <Route path="album2" element={<GalerieDetails2/>}/>
          <Route path="Travaux" element={<Travaux/>}/>
        </Route>
        <Route path='/media'>
            <Route index element={<Media/>}/>
          <Route path="album" element={<MediaDetails/>}/>
          <Route path="album1" element={<MediaDetails1/>}/>
          <Route path="album2" element={<MediaDetails2/>}/>
        </Route>
        <Route path='/institut'>
          <Route path="institut" element={<Institut/>}/>
          <Route path="ecoles" element={<Ecoles/>}/>
        </Route>
        <Route path='/' element={<Forbiden/>}/>
        <Route path="Travaux" element={<Travaux/>}/>
        <Route path='actualite' element={<Actualite/>}/>
        <Route path='concours' element={<Concours/>}/>
        <Route path='anniversaire' element={<Anniversaire/>}/>
        
      </Routes>
    </>
  );
}

export default App;
