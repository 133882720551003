import React, { useEffect, useState } from "react";
import "./Tab.css";

const Tab = ({ children, state, active = 0 }) => {
  const [activeTab, setActiveTab] = useState(active);
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    let data = [];

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const {
        props: { tab, children },
      } = element;
      data.push({ tab, children });
    });

    setTabsData(data);
  }, [children]);
  
  useEffect(() => {
    return () => {
      setActiveTab(state)
    }
  }, [])
  
  return (
    <div className="w-full h-full overflow-hidden flex md:flex-row flex-col">
      <ul className="w-full md:w-1/3 px-4 md:px-0 md:pr-8 md:mr-8 mb-4 md:mb-4  flex items-start justify-start flex-col md:overflow-hidden md:border-r border-solid border-slate-200">
        {tabsData.map(({ tab }, idx) => (
          <button key={idx} className={`group w-full h-8 md:h-auto text-[0.6rem] md:text-xs ${idx===activeTab?"bg-primary text-white hover:text-black":"md:bg-slate-50 bg-gray-200"} duration-500 hover:bg-primary-light px-8 py-4 mb-1 md:mb-2 last:mb-0 rounded cursor-pointer flex items-center justify-between md:border border-solid border-slate-200 ${idx === activeTab ? "active" : ""}`} onClick={() => {setActiveTab(idx)}}>
              <div>{tab}</div>
              <p className="text-[6px] md:text-sm">{">"}</p>
          </button>
        ))}
      </ul>
      <div className={`w-full h-full`}>
        {tabsData[activeTab] && tabsData[activeTab].children}
      </div>
    </div>
  );
};

const TabPane = ({ children }) => {
  return { children };
};

Tab.TabPane = TabPane;

export default Tab;