import React,{useState} from 'react'
import { FaTimes, FaBars} from "react-icons/fa"
import { AiFillHome} from "react-icons/ai"
import { Link, Outlet } from 'react-router-dom';
import '../../../index.css'
import logo from '../../../res/images/logo Mawa.png';
import './institut.css';

const Institut = () => {

    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)

    return (
        <>
            <div className='w-full xl:px-8 md:sticky top-0  z-50 place-items-start gap-2'>
                <div className='w-full h-12 flex justify-between items-center px-4 md:hidden bg-white md:bg-primary  z-50 shadow-xl'>
                    <Link to="/"><AiFillHome className='text-2xl text-primary'/></Link>
                    <Link to="/"><img className='md:hidden w-8 h-8' src={logo} alt="" /></Link>
                    {click ? (<FaTimes onClick={closeMenu} className='text-xl text-primary'/>) : (<FaBars onClick={handleClick} className='text-xl text-primary'/>)}
                </div>
                <div className={!click ? ('w-full md:h-12 lg:h-16 h-screen hidden relative md:flex md:justify-center md:items-center md:bg-primary text-white md:shadow-xl lg:rounded-none'):('w-full flex justify-center md:relative z-40 md:h-16 h-screen md:flex md:justify-center md:items-center  md:bg-primary text-white md:shadow-xl')}>
                    <ul className='institut w-fit mt-0 md:mt-0 flex justify-start items-center flex-col md:justify-center md:items-center md:flex-row bg- md:bg-none'>
                    <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-bold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.9rem] text-[0.6rem] md:text-red-600 md:hover:text-black duration-500'><Link to="/institut/ecoles">Mawa Business Institut</Link></div>
                        </li>
                    <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-bold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.9rem] text-[0.6rem] md:text-red-600 md:hover:text-black duration-500'><Link to="/Travaux">Mawa Business Institut</Link></div>
                        </li>
                        <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-bold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.9rem] text-[0.6rem] md:text-red-600 md:hover:text-black duration-500'><Link to="/Travaux"> Mawa Building</Link></div>
                        </li>
                        <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-bold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.9rem] text-[0.6rem] md:text-red-600 md:hover:text-black  duration-500'><Link to="/Travaux">Mawa Santé</Link></div>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <Outlet/>
        </>
    )
}

export default Institut