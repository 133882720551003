import React from 'react';

const Contacts = () => {
    return(
        <div>
            <div className='w-full xl:px-8 flex justify-center items-center flex-col font-normal lg:text-sm text-xs'>
                <div className='w-[100%] pb-4 md:pb-4 lg:pb-8 xl:pb-10 md:mt-2 mt-1 bg-white text-black rounded flex justify-start items-center flex-col'>
                    <div className='lg:text-2xl md:text-xl text-sm font-bold lg:py-20 md:py-12 py-10'>Nous contacter</div>
                    <form action='' method='POST' className='w-full md:w-[700px] flex justify-center items-center flex-col'>
                        <div className='w-full md:px-0 px-8 grid grid-cols-1 md:grid-cols-2 grid-row-2 gap-y-4 md:gap-y-8 md:gap-x-8'>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" placeholder='Prenom & Nom' required/>
                            </div>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" placeholder='Email' required/>
                            </div>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" placeholder='Objet' required />
                            </div>
                            <div className='w-full h-10 lg:h-12 flex justify-center items-center rounded'>
                                <input className='w-full h-full rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="tel" pattern="[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{2}" placeholder='Téléphone' required />
                            </div>
                        </div>
                        <div className='w-full md:px-0 px-8 mt-4 md:mt-8 flex justify-center items-center rounded'>
                            <textarea className='w-full h-36 max-h-60 resize-none text-start py-4 rounded bg-gray-100 border border-solid border-gray-200 outline-none px-4' type="text" placeholder='Message . . .' required />
                        </div>
                        <div className='md:px-0 px-8 w-full md:w-fit'>
                            <input type="submit" className='w-full md:w-fit h-12 px-10 my-4 md:my-8 rounded bg-primary-light text-black font-normal flex justify-center items-center duration-150 hover:px-10 hover:bg-primary hover:text-white shadow-lg shadow-gray-100' name='Envoyer' value='Envoyer'/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contacts;