import React from "react";
import Header from "../../elements/header";
import Footer from "../../elements/footer";
import Menu from "../../elements/menu/menu";
import VideoSpot from './video/AnniversaireVideo'
import imageServiceTechnique from '../../../res/03/IMG-20211001-WA0015.png';
import Similaire from "./AutreArticle";



const Actualite = () => {
    return(
        <div>
            <Header/>
             <Menu />
            <div className='w-6/7 xl:px-8 mb-0 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-0 mt-1 bg-white text-black  pb-10 md:pb-28'>
                    <div className='w-6/6 mt-0flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                       <img className="w-screen h-aut" src= { imageServiceTechnique } alt="" />   
                    </div>
                 </div>         
            </div> 
            <div className='w-6/7  xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-1 mt-1 bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                    <div className='lg:py-10 md:py-8 py'>
                        <h1 className="mt-0 lg:text-2xl md:text-xl text-sm text-center font-bold">Jeux concours les Etoiles de Mawa</h1>
                        <p className="mt-5 ml-36 mr-36 lg:text-[0.9rem] md:text-[0.9rem] text-base text-justify flex justify-center items-center md:items-start flex-col font-semi-bold">Nous sommes ravis de vous accueillir pour célébrer une décennie de créativité, d'inspiration et de succès. Depuis nos débuts modestes il y a dix ans, le Groupe Mawa a évolué pour devenir bien plus qu'une simple communauté : c'est une famille, une source infinie de talent et d'innovation.Au fil des ans, nous avons grandi, appris et créé ensemble. Les projets que nous avons entrepris, les défis que nous avons relevés et les réussites que nous avons célébrées ont forgé l'identité unique du Groupe Mawa. Chacun de vous, membres, partenaires et supporters, a contribué à faire de ces dix ans une aventure extraordinaire </p>   
                     </div> 
                     <div className='w-6/6 ml-36 mr-36 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                        <VideoSpot /> 
                     </div>
                     
                </div>   
            </div>
            <Similaire />
            <Footer/>
        </div>
    )
}
export default Actualite;