import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/images/5-Signs-Your-Hotel-Should-Invest-in-Technology.jpeg';
import imageServiceTechnique from '../../../../res/formations/MANAGEMENT HOTELIER_page-0001.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelereecham from './accelereecham';
import Comple from "../comple";


const Chambre = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Vallet - Fille de chambre"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className=" md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16 ">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">Les objectifs d'une formation pour devenir femme de chambre ou agent d'entretien dans un hôtel peuvent varier en fonction des politiques spécifiques de l'établissement et des normes de l'industrie. Cependant, voici quelques objectifs généraux qui peuvent être poursuivis lors de ce type de formation:<br />
         <span class="text-semi-bold"> - </span>Maîtrise des Techniques de Nettoyage : La formation vise à enseigner les techniques de nettoyage efficaces pour assurer un haut niveau d'hygiène et de propreté dans les chambres d'hôtel.<br />
         <span class="text-semi-bold"> - </span>Compétences en Communication : Développement de compétences de communication pour interagir de manière professionnelle avec les clients, les collègues et la direction.<br />
         <span class="text-semi-bold"> - </span>Service à la Clientèle : Développement d'une attitude orientée vers le service à la clientèle, en veillant à ce que les besoins des clients soient satisfaits de manière professionnelle.<br />
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">Les débouchés professionnels pour une femme de chambre ou un agent d'entretien dans un hôtel peuvent varier en fonction de divers facteurs, tels que l'emplacement géographique, la taille de l'établissement et l'expérience individuelle. Voici quelques perspectives générales :<br />
           1. Postes de Supervision et de Gestion<br />
           2. Entrepreneuriat<br /> 
           3. Réseau Professionnel<br />
           4. Spécialisation<br />
           5. Évolution au sein de l'hôtel
             </p>
             <a href={document} download className='absolute mt-8 ml-64  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>
    <Accelereecham />
    <Comple />
    <Footer />
   
</div>
    
  )
 
}

export default Chambre