import React from 'react';
import { FaSearch, FaTwitterSquare, FaFacebookSquare } from "react-icons/fa"
import { Link } from 'react-router-dom';
import '../../index.css';
import logomawa from '../../res/images/logo-groupe-mawa.png';
import drapeau from '../../res/images/logo Mawa.png';
import telephone from '../../res/images/telephone.svg';
import calendrier from '../../res/images/calendrier.svg';
import marqueur from '../../res/images/marqueur.svg';
import enveloppe from '../../res/images/enveloppe.svg';

const Header = () => {
    return(
        <>
            <header className='w-full hidden md:flex justify-center items-center flex-col xl:px-8'>
                <div className='w-[100%] h-24 flex justify-between items-center lg:px-8 md:px-4 bg-white text-black rounded'>
                    <div className='h-full flex justify-center items-center'>
                        <div className='lg:mr-8 md:mr-4'>
                           <Link className='' to="/accueil/accueil"> <img className='xl:w-[95px] lg:w-16 xl:h-20 mt-4 md:mb-0 ' src={logomawa} alt="" /></Link>
                        </div>
                        <Link className='' to="/presentation/ecoles"> <img className='xl:w-[100px] lg:w-12 xl:h-[70px] lg:h-12 md:w-16 md:h-16 ml-1 ' src={drapeau } alt="" /></Link>
                    </div>
                    <div className='grid grid-cols-2 md:gap-x-4 lg:gap-x-0 md:gap-y-2 lg:gap-y-4 grid-rows-2 place-items-start lg:px-0 md:px-2'>
                        <div className='flex justify-center xl:items-center md:items-start'>
                            <img className='xl:w-4 lg:w-[10px] md:w-2 lg:h-[10px] md:h-2 mr-2 md:mt-[3px]' src={calendrier} alt="" />
                            <p className='lg:text-[0.6rem] md:text-[0.5rem] xl:text-[0.7rem] text-left'>Ouvert du Lundi au  Samedi <br className='lg:hidden'/>de 8h00 à 18h30</p>
                        </div>
                        <div className='flex justify-center xl:items-center md:items-start'>
                            <img className='xl:w-4 lg:w-[10px] md:w-2 lg:h-[10px] md:h-2 mr-2 md:mt-[3px]' src={marqueur} alt="" />
                            <p className='lg:text-[0.6rem] md:text-[0.5rem] xl:text-[0.7rem] text-left'>Groupe MAWA <br className='lg:hidden'/>Keur Massar Dakar Sénégal</p>
                        </div>
                        <div className='flex justify-center xl:items-center md:items-start'>
                            <img className='xl:w-4 lg:w-[10px] md:w-2 lg:h-[10px] md:h-2 mr-2 md:mt-[3px]' src={enveloppe} alt="" />
                            <p className='lg:text-[0.6rem] md:text-[0.5rem] xl:text-[0.7rem] text-left'>infos@complexemawa.com</p>
                        </div>
                        <div className='flex justify-center xl:items-center md:items-start'>
                            <img className='xl:w-4 lg:w-[10px] md:w-2 lg:h-[10px] md:h-2 mr-2 md:mt-[3px]' src={telephone} alt="" />
                            <p className='lg:text-[0.6rem] md:text-[0.5rem] xl:text-[0.7rem] text-left'>+221 77 615 77 70</p>
                        </div>
                    </div>
                    <div className='h-full flex justify-center items-center'>
                        <div className='flex justify-center items-center md:h-8 lg:w-28 xl:w-40 rounded bg-white mr-2 border border-solid border-gray-300 border-1'>
                            <input className='border-none outline-none w-full h-full rounded lg:text-xs md:text-[0.6rem] px-4 text-black' type="text" placeholder='Rechercher . . .'/>
                        </div>
                        <div className='flex justify-center items-center md:h-8 md:w-8 rounded bg-primary mr-2 cursor-pointer hover:bg-gray-600 duration-150"'>{<FaSearch size={12} color="white"/>}</div>
                        <div className='xl:flex justify-center items-center lg:hidden md:h-8 md:w-8 rounded bg-secondary mr-2 cursor-pointer hover:bg-gray-600 duration-150'>{<FaFacebookSquare size={18} color="white"/>}</div>
                        <div className='xl:flex justify-center items-center lg:hidden md:h-8 md:w-8 rounded bg-secondary cursor-pointer hover:bg-gray-600 duration-150'>{<FaTwitterSquare size={18} color="white"/>}</div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;

