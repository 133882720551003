import React from 'react';
import { useState, useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "./Slider.css";
import {sliderData} from './slider-data';
import slidercoiffure from '../../../../res/images/SliderCoiffure.jpg';
import plancuisine from '../../../../res/images/plancuisine.jpg';
import slidercouture from '../../../../res/images/SliderCouture.png';

const Hero = () => {
    
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;
  const autoScroll = true;

  let slideInterval;
  let intervalTime = 8000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    console.log("next");
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
    console.log("prev");
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlide]);
    return(
        <div>
            <div className='w-full flex justify-center items-center xl:px-8 text-[0.8rem] md:text-[1.0rem]'>
                <div className='w-[100%] flex justify-center items-center flex-col relative pt-12 md:pt-0 shadow-sm shadow-gray-800/10'>
                    <div className='w-[100%] h-[200px] md:h-[350px] lg:h-[600px] relative overflow-hidden '>
                    {sliderData.map((slide, index) => {
                        return (
                            <div key={index+10} className='flex justify-center items-center'>
                                <div className={index === currentSlide ? "  w-full h-full" : "slide"} key={index}>
                                    {index === currentSlide && (<img src={slide.image === 1 ? (slidercoiffure) : (slide.image === 2 ? (plancuisine) : (slide.image === 3 && (slidercouture)))} alt="slide" className="w-full object-fill xl:rounded-b"/>)}
                                </div>
                            </div>
                        );
                    })}
                    </div>
                    <div className='absolute w-full h-0 md:h-[50px] lg:h-[100px] bottom-0   from-gray-800 to-gray-800/0'></div>
                    <div className='w-[100%] h-[200px] md:h-[350px] lg:h-[600px] absolute left-0 top-12 md:top-0 text-black flex justify-between items-center flex-col  xl:rounded-b'>
                        <div className='w-full h-full flex justify-between items-center'>
                            <div className='flex justify-center items-center'>
                                <AiOutlineArrowLeft className='md:w-10 w-6 md:h-10 h-6 flex justify-center items-center rounded-r p-2 text-white bg-black/50' onClick={prevSlide} />
                            </div>
                            <div className='w-fit flex justify-center items-center flex-col'>
                                {sliderData.map((slide, index) => {
                                    return (
                                        <div key={index+10} className='flex justify-center items-center'>
                                                {index === currentSlide && (
                                                    <div className="flex justify-center items-center flex-col">
                                                        <h2 className='md:mb-8 mb-2 lg:text-4xl text-xl font-semibold text-white slide-up'>{slide.heading}</h2>
                                                        <p className='lg:mb-16 md:mb-8 mb-4 text-2xl md:text-sm lg:text-[50px] text-white w-[90%] md:w-[70%] xl:leading-10 md:leading-9 leading-4 slide-down text-center'>{slide.description}</p>
                                                        <a className='text-black' href=""><div className='md:h-12 h-8 md:px-8 px-4 rounded-bl-lg bg-secondary text-white flex justify-center items-center duration-150 hover:px-10 hover:bg-primary hover:text-white'>Plus de détails</div></a>
                                                    </div>
                                                )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='flex justify-center items-center'>
                                <AiOutlineArrowRight className='md:w-10 w-6 md:h-10 h-6 flex justify-center items-center rounded-l p-2 text-white bg-black/50' onClick={nextSlide} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;