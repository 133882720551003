import React from 'react';
import {DefaultPlayer as Video } from 'react-html5video';
import intoVideo from '../video/Interview de mme Wathie Directrice du Groupe Mawa.mp4';
import 'react-html5video/dist/styles.css'



const VideoPlayer = () => {
     return (
        <Video autoPlay loop    onCanPlayThrough={() =>{
            
            console.log('video play')
        }
        }>
            <source src={intoVideo} type='video/MP4' />
            

        </Video>

    )

}

export default VideoPlayer; 