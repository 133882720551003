import img1 from '../../../res/images/DSC_9934-768x512.jpg';
import img2 from '../../../res/images/IMG-20201125-WA0012.jpg';
import img3 from '../../../res/images/IMG-20201125-WA0014.jpg';
import img5 from '../../../res/images/IMG-20201125-WA0015.jpg';
import img4 from '../../../res/images/IMG-20201123-WA0013.jpg';
import img6 from '../../../res/images/IMG-20201123-WA0015.jpg';
import img7 from '../../../res/images/IMG-20201123-WA0020.jpg';
import img8 from '../../../res/images/IMG-20201123-WA0028.jpg';
import img9 from '../../../res/images/IMG-20201123-WA0025.jpg';
import img10 from '../../../res/images/IMG-20201123-WA0031.jpg';
import img11 from '../../../res/images/IMG-20201123-WA0032.jpg';
import img12 from '../../../res/images/IMG-20201123-WA0035.jpg';
import img13 from '../../../res/images/blank.jpg';
import img14 from '../../../res/images/blank.jpg';
import img15 from '../../../res/images/blank.jpg';
import img16 from '../../../res/images/blank.jpg';


export const Files1 =[
    {
        index : "1",
        filetype : "img",
        filepath : img1
    },
    {
        index : "2",
        filetype : "img",
        filepath : img2
    },
    {
        index : "3",
        filetype : "img",
        filepath : img3
    },
    {
        index : "4",
        filetype : "img",
        filepath : img4
    },
    {
        index : "5",
        filetype : "img",
        filepath : img5
    },
    {
        index : "6",
        filetype : "img",
        filepath : img6
    },
    {
        index : "7",
        filetype : "img",
        filepath : img7
    },
    {
        index : "8",
        filetype : "img",
        filepath : img8
    },
    {
        index : "9",
        filetype : "img",
        filepath : img9
    },
    {
        index : "10",
        filetype : "img",
        filepath : img10
    },
    {
        index : "11",
        filetype : "img",
        filepath : img11
    },
    {
        index : "12",
        filetype : "img",
        filepath : img12
    },
    {
        index : "13",
        filetype : "img",
        filepath : img13
    },
    {
        index : "14",
        filetype : "img",
        filepath : img14
    },
    {
        index : "15",
        filetype : "img",
        filepath : img15
    },
    {
        index : "16",
        filetype : "img",
        filepath : img16
    },
]