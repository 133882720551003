import React from 'react';
import {DefaultPlayer as Video } from 'react-html5video';
import intoVideo from '../video/Jeux Concours les Etoiles de Mawa ( Phase 2 Couture ).mp4';
import 'react-html5video/dist/styles.css'



const ConcoursVideo = () => {
     return (
        <Video autoPlay loop    onCanPlayThrough={() =>{
            
            console.log('video play')
        }
        }>
            <source src={intoVideo} type='video/MP4' />
            

        </Video>

    )

}

export default ConcoursVideo; 