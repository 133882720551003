import React,{useState} from 'react'
import { FaTimes, FaBars} from "react-icons/fa"
import { AiFillHome} from "react-icons/ai"
import { Link, Outlet } from 'react-router-dom';
import '../../../index.css'
import logo from '../../../res/images/logo Mawa.png';
import './menu.css';

const Menu = () => {

    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)

    return (
        <>
            <div className='w-full xl:px-8 md:sticky top-0 fixed z-50'>
                <div className='w-full h-12 flex justify-between items-center px-4 md:hidden bg-white md:bg-primary absolute z-50 shadow-xl'>
                    <Link to="/"><AiFillHome className='text-2xl text-primary'/></Link>
                    <Link to="/"><img className='md:hidden w-8 h-8' src={logo} alt="" /></Link>
                    {click ? (<FaTimes onClick={closeMenu} className='text-xl text-primary'/>) : (<FaBars onClick={handleClick} className='text-xl text-primary'/>)}
                </div>
                <div className={!click ? ('w-full md:h-12 lg:h-16 h-screen hidden relative md:flex md:justify-center md:items-center md:bg-primary text-white md:shadow-xl lg:rounded-none'):('w-full flex absolute md:relative z-40 md:h-16 h-screen md:flex md:justify-center md:items-center backdrop-blur-md  backdrop-brightness-50 bg-white/25 md:bg-primary text-white md:shadow-xl')}>
                    <ul className='menu w-fit mt-12 md:mt-0 flex justify-start items-center flex-col md:justify-center md:items-center md:flex-row bg- md:bg-none'>
                    <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-semibold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.8rem] text-[0.6rem] md:text-white md:hover:text-black duration-500'><Link to="/presentation/ecoles">Presentation</Link></div>
                        </li>
                        <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-semibold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.8rem] text-[0.6rem] md:text-white md:hover:text-black duration-500'><Link to="/formations/accueil"> Instituts</Link></div>
                            <ul className="drop-menu md:absolute md:pt-8">
                               <li class="hover-me" ><Link className='' to="/formations/accueil"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Complexe Mawa</div></Link>
                                    <div className='sub-menu-2'>
                                         <ul className="drop-menu md:absolute md:pt-8">
                                         <li class="hover-me1"><Link className='' to="/formations/carte"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2  md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Formations à la carte</div></Link>
                                     <div className='sub-menu-3'>
                                              <ul className="drop-menu md:absolute md:pt-8">
                                                <Link className='' to="/formations/carte"><div className='text-black ml-4 md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Couture</div></Link>
                                                <Link className='' to="/formations/carte"><div className='text-black ml-4 md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Stylisme</div></Link>
                                                <Link className='' to="/formations/carte"><div className='text-black ml-4 md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Coiffure</div></Link>
                                             </ul>
                                      </div>
                                  </li>
                                  <Link className='' to="/formations/esthetique"><div className='text-black  md:mb-2 mb-0  px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Esthétique</div></Link>
                                  <Link className='' to="/formations/gestion"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Gestion hôtelière</div></Link>
                                  <Link className='' to="/formations/stylisme"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Stylisme</div></Link>
                                  <Link className='' to="/formations/coiffure"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Coiffure</div></Link>
                                  <Link className='' to="/formations/couture"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Couture - Modélisme</div></Link>
                                  <Link className='' to="/formations/hotel"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Hôtellerie et Restauration</div></Link>
                                  <Link className='' to="/formations/chambre"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Vallet - Fille de chambre</div></Link>
                                  <Link className='' to="/formations/couture"><div className='text-black  md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Tourisme</div></Link>
                                  <Link className='' to="/formations/receptionniste"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Réceptionniste - gouvernante</div></Link>
                                        </ul>
                                    </div>
                               </li>  
                               <li class="hover-me" ><Link className='' to="../Travaux"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>MBI</div></Link>
                               </li> 
                               <li class="hover-me" ><Link className='' to="../Travaux"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Mawa Building</div></Link>
                               </li> 
                               <li class="hover-me" ><Link className='' to="../Travaux"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Mawa Santé</div></Link>
                               </li>            
                            </ul>
                        </li>
                        <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <div className='px-4 md:py-1 py-2 md:font-normal font-semibold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:hover:bg-white lg:text-[0.8rem] text-[0.6rem] md:text-white md:hover:text-black duration-500'><Link to="/contact/contact"> Contact</Link></div>
                            <ul className="drop-menu md:absolute md:pt-8">
                                <Link className='' to="/contact/preinscription"><div className='text-black md:mb-2 mb-0 px-4 md:py-2 py-2 md:border-none border-b border-solid border-white w-screen md:w-[250px] md:rounded rounded-none bg-primary-light md:bg-white md:hover:bg-primary-light flex justify-center md:block lg:text-[0.8rem] text-[0.6rem]'>Préinscription</div></Link>
                            </ul>
                        </li>
                       
                        <li className='item md:ml-4 md:hover:bg-white rounded '>
                            <Link className='' to="/galerie"><div className='px-4 md:py-1 py-2 md:font-normal font-semibold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:hover:bg-white lg:text-[0.8rem] text-[0.6rem] md:text-white md:hover:text-black duration-500'>MW Restaurant</div></Link>
                        </li>
                        <li className='item md:ml-4 md:hover:bg-white rounded '>
                        <div className='px-4 md:py-1 py-2 md:font-normal font-semibold md:border-none border-b border-solid border-primary-light w-screen md:w-fit md:rounded bg-primary md:bg-primary-light md:hover:bg-white lg:text-[0.8rem] text-[0.6rem] md:text-white md:hover:text-black duration-500'><Link to="/media/"> Médiathèque</Link></div>
                        </li>
                    </ul>
                </div>
            </div>
            <Outlet/>
        </>
    )
}

export default Menu