export const sliderData = [
  {
    image: 1,
    heading: " Groupe Mawa",
    description: "Devenez esthéticien - coiffeur avec le Complexe Mawa",
  },
  {
    image: 2,
    heading: "Groupe Mawa",
    description: "Réveillez le chef qui sommeil en vous le Complexe Mawa",
  },
  {
    image: 3,
    heading: "Groupe Mawa",
    description: "Devenez styliste  - couturier avec le Complexe Mawa",
  },
];
