import React from 'react';
import image from '../../../res/images/IMG-20200609-WA0012.jpg';
import { Link } from 'react-router-dom';


const Presentation = () => {
    return(
        <div>
            <div className='w-sreen xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center'>
                <div className='w-full md:h-fit md:mt-2 mt-1 bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                    <div className='lg:py-20 md:py-12 py-10'>
                        <h2 className="lg:text-2xl md:text-xl text-sm text-center font-bold">Description du Groupe Mawa</h2>
                    </div>
                    <div className='w-full flex h-full justify-center items-center md:items-start md:flex-row flex-col md:px-0 px-8'>
                        <div className='lg:w-[400px] md:w-[400px] w-full md:max-w-none max-w-[330px] lg:h-[250px] md:h-[350px] h-[200px] mt-24 md:mr-8 lg:mr-16 md:mb-10 bg-maire bg-cover flex justify-center items-end rounded hadow-lg shadow-gray-100'>
                            <div className='group w-full max-w-[400px] md:w-[400px] h-[350px] bg-contain bg-blank flex justify-center items-center relative rounded md:hover:scale-110 duration-300 shadow-lg  '>
                            <img className='w-full max-w-[400px] md:w-[400px] h-[350px] ' src={image} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full  group-hover:rounded duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-[0.6rem] md:text-xs md:pb-1'>Madame Aîssata WATHIE</p>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Présidente du Groupe Mawa</p>
                            </div>
                        </div>
                        </div>
                        <div className='xl:w-[650px] lg:w-[500] md:w-[400px] w-full overflow-hidden md:px-0 px-2 flex justify-center items-center md:items-start flex-col  '>
                            <p className='text-[0.9rem] md:text-[0.9rem] text-justify font-semi-bold leading-5 md:leading-7'>
                            <ul class="list-disc">
                               <li>
                                 Le <span class="text-blue-600"><a href="#">Groupe MAWA</a></span> est une structure intervenant dans le domaine de l’éducation (formation professionnelle ) , du commerce et de la production audiovisuelle. En effet,  l’offre de formation du groupe s’articule autour de quatre entités à savoir le <span class="text-blue-600"><a href="#">COMPLEXE MAWA</a></span>, le <span class="text-blue-600"><a href="#">MAWA BUSINESS INSTITUTE</a></span>, le <span class="text-blue-600"><a href="#">MAWA SANTE</a></span> et le <span class="text-blue-600"><a href="#">MAWA BULDING</a></span>. Quant à nos activités commerciales, elles s’articulent autour de <span class="text-blue-600"><a href="#">MASALIKUL JINAAN STORE</a></span> et <span class="text-blue-600"><a href="#">MAWA BOUTIQUE SHOPPING</a></span>. Nos activités de production audiovisuelle sont du ressort de <span class="text-blue-600"><a href="#">KALADO PRODUCTION</a></span>.
                                </li> <li>
                                 Le <span class="text-blue-600"><a href="#">Groupe MAWA</a></span> comporte au total sept entités à savoir le <span class="text-blue-600"><a href="#">COMPLEXE MAWA</a></span>, le <span class="text-blue-600"><a href="#">MAWA BUSINESS INSTITUTE</a></span>, le <span class="text-blue-600"><a href="#">MAWA SANTE</a></span>, le <span class="text-blue-600"><a href="#">MAWA BULDING</a></span>, le <span class="text-blue-600"><a href="#">MASALIKUL JINAAN STORE</a></span>, le <span class="text-blue-600"><a href="#">MAWA BOUTIQUE SHOPPING</a></span> et  <span class="text-blue-600"><a href="#">KALADO PRODUCTION</a></span>.
                                 </li></ul>
                            </p>
                            <Link className='shadow-lg' to="./presentation/about"><div className='md:w-fit w-full md:max-w-none max-w-[330px] md:h-12 h-10 px-8 md:mt-4 mt-4 rounded bg-primary text-black md:text-[0.7rem] text-[0.6rem] font-normal flex justify-center items-center duration-150 hover:px-10 hover:bg-secondary hover:text-white'>Lire la suite</div></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation;