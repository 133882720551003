import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/images/tourisme.jpg';
import imageServiceTechnique from '../../../../res/formations/couture-modelisme.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelereetou from './accelereetou';
import Comple from "../comple";

const Tourisme = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Tourisme"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className=" md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16 ">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold'>Objectifs de la formation </h1>
         <p class="text-justify">
         Les objectifs de la formation dans le domaine du tourisme peuvent varier en fonction de la formation spécifique que vous suivez, de votre niveau de carrière actuel et de vos objectifs personnels. Cependant, voici quelques objectifs généraux que de nombreuses personnes cherchent à atteindre en suivant une formation dans le tourisme:<br />
         <span class="text-semi-bold">-</span> Acquérir des compétences professionnelles : La formation touristique vise souvent à enseigner des compétences spécifiques nécessaires pour travailler dans l'industrie, telles que la gestion de voyage, la planification de circuits, la communication interculturelle, la gestion hôtelière, la gestion des destinations touristiques, etc. 
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         Le secteur du tourisme offre une variété de débouchés professionnels, que vous pouvez explorer en fonction de vos compétences, de votre formation, de vos intérêts et de votre expérience. Voici quelques débouchés professionnels courants dans le domaine du tourisme:<br />
        1- Guide touristique <br />
        2- Agent de voyage <br />
        3- Gestionnaire d'hôtel <br />
        4- Spécialiste en marketing touristique <br />
        5- Gestionnaire de destination touristique
             </p>
             <a href={document} download className='absolute mt-8 ml-64  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Accelereetou />
    <Comple />
    <Footer />
</div>
    
  )
 
}

export default Tourisme