import React from 'react'
import Header from '../../elements/header';
import Footer from '../../elements/footer';
import Menu from '../../elements/menu/menu';
import Restaurant from './restaurant'
import MenuResto from './menuResto'



const Galerie = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Restaurant />
        <MenuResto />
        <Footer/>
    </div>
  )
}

export default Galerie