import React from 'react'

const Page_info = ({ name, link, bg, textStyle, translate }) => {
    return (
        <div className='w-full mt-12 md:mt-0 xl:px-8 flex justify-center items-center flex-col font-normal lg:text-sm text-xs'>
            <div className='relative w-[100%] h-80 md:h-110 bg-primary-light text-black overflow-hidden'>
                <img src={bg} className={`w-full ${translate} `}/>
                <div className='absolute top-0 left-0 w-full h-full  mix-blend-color'></div>
                <div className='absolute top-0 left-0 w-full h-full  from-slate-900/50 md:from-slate-800/90 to-white/0'></div>
                <div className='absolute top-0 left-0 w-full h-full justify-between flex md:justify-center items-end md:items-start flex-row md:flex-col p-2 px-4 md:px-6 lg:px-20'>
                    <p className={`lg:text-4xl md:text-2xl text-[0.6rem] md:font-bold md:mb-4 ${textStyle}`}>{name}</p>
                    <p className={`lg:text-sm md:text-xs text-[0.5rem] font-normal ${textStyle}`}>{link}</p>
                </div>
            </div>
        </div>
    )
  }
export default Page_info