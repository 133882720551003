import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/divers-createurs-de-mode-masculins-et-feminins-au-travail-discutant-et-regardant-le-tissu-couturi.jpg';
import imageServiceTechnique from '../../../../res/formations/stylisme.png'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelereesty from './accelereesty';
import Comple from "../comple";

const Couture = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Stylisme"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className=" md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
         Les objectifs de la formation en stylisme varient en fonction du niveau d'enseignement, du programme spécifique et des aspirations individuelles de chaque étudiant. Cependant, voici quelques objectifs généraux que l'on peut associer à une formation en stylisme:<br />
         <span class="text-semi-bold">-</span> Développer des compétences techniques : L'une des principales missions de la formation en stylisme est d'enseigner aux étudiants les compétences techniques nécessaires pour concevoir, créer et produire des vêtements. Cela comprend l'apprentissage de la modélisation, de la couture, de la création de motifs, de la gestion des matériaux, etc...<br />
         <span class="text-semi-bold">-</span> Cultiver la créativité et le style personnel : La formation en stylisme encourage les étudiants à développer leur créativité et à trouver leur propre style. Ils sont encouragés à créer des collections de mode uniques qui captivent leur vision artistique.
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         Les débouchés professionnels pour les diplômés d'une formation en stylisme sont variés et peuvent inclure un large éventail de carrières dans l'industrie de la mode. Voici quelques-uns des débouchés professionnels courants pour les stylistes:<br />
          1- Designer de mode <br />
          2- Styliste de mode
             </p>
             <a href={document} download className='absolute ml-64  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Accelereesty />
    <Comple />
    <Footer />
</div>
    
  )
 
}

export default Couture