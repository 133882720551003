import React from 'react'
import Header from '../../elements/header';
import Footer from '../../elements/footer';
import Menu from '../../elements/menu/menu';
import Page_info from '../../elements/page_info';
import bg from '../../../res/04/IMG_4599.jpg';
import Partage from '../../elements/partage';
import { Link } from 'react-router-dom';
import restauration from '../../../res/images/IMG_0081-768x512.jpg'
import imageServiceTechnique from '../../../res/images/graduate-2038864_640.jpg'
import imageServiceTechnique2 from '../../../res/02/billet-couture-768x504.jpg'


const Media = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Médiathéque"} link={"Médiathéque  > Albums"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
        <div className="w-sreen xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center">
            <div className='w-full md:h-fit md:mt-0 mt-1 bg-white text-black rounded pt-4 p-0 md:p-8'>
                <div className='py-6'>
                    <h2 className="md:text-xl text-sm text-left font-bold"><span className='text-primary font-black'>|</span>&nbsp;&nbsp;Nos différents albums</h2>
                </div>
                <div className='w-full md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-4 md:grid-cols-3 md:gap-x-8 md:gap-y-8'>
                    <Link state={{page : 0}} to={"/media/album"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={restauration} alt="" />
                            <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500'> Restauration</p>
                            </div>
                        </div>
                    </Link>
                    <Link state={{page : 1}} to={"/media/album1"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                            <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500'>Remise de diplôme</p>
                            </div>
                        </div>
                    </Link>
                    <Link state={{page : 1}} to={"/media/album2"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique2} alt="" />
                            <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500'>Couture & Coiffure</p>
                            </div>
                        </div>
                    </Link>
                    <Link state={{page : 0}} to={"/media/album"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                            <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500'>autres images</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <Partage />
        <Footer/>
    </div>
  )
}

export default Media