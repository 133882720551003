import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/IMG_0041-768x576.jpg';
import imageServiceTechnique from '../../../../res/formations/esthetique.png'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Acceleresthe from './acceleresthe';
import Comple from "../comple";
const Esthetique = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Esthétique"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className=" md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16 ">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
         Les objectifs de la formation en esthétique varient en fonction du programme spécifique et du niveau de formation visé. Cependant, voici quelques objectifs généraux qui sont souvent poursuivis dans ce domaine :<br />
         <span class="text-semi-bold"> - </span> Maîtriser les compétences techniques : Les formations en esthétique visent à enseigner aux étudiants les compétences techniques nécessaires pour fournir des soins de beauté et de bien-être de haute qualité. Cela peut inclure des compétences en soins de la peau, maquillage, coiffure, manucure, pédicure, massothérapie, etc... <br />
         <span class="text-semi-bold"> - </span> Comprendre l'anatomie et la physiologie de la peau : Les esthéticiens doivent avoir une connaissance approfondie de l'anatomie et de la physiologie de la peau pour pouvoir fournir des soins adaptés à chaque client et pour identifier les problèmes de peau.
             </p>
             <h1 className='m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold'>Débouchés professionnels </h1>
         <p class="text-justify"> Après avoir suivi une formation en esthétique, il existe de nombreux débouchés professionnels dans l'industrie de la beauté et du bien-être. Voici quelques-uns des métiers auxquels vous pourriez :<br />
              1. Esthéticien(ne) <br />
              2. Maquilleur(se) <br />
              3. Manucure/Pédicure <br />
              4. Massothérapeute <br />
              5. Spa Manager <br />
             </p>
             <a href={document} download className='absolute ml-64  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
      </div>
    <Acceleresthe />
    <Comple />       
    <Footer />
</div>
    
  )
 
}

export default Esthetique