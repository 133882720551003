
import img1 from '../../../res/restaurant/757_347-8.png';
import img2 from '../../../res/restaurant/1548_202.png';
import img7 from '../../../res/restaurant/png-clipart-french-fries-kebab-souvlaki-konak-restaurant-bv-mixed-grill-sis-kebab-french-fries-kebab.png';
import img4 from '../../../res/restaurant/big burger viande.png';
import img6 from '../../../res/restaurant/fast-food-sandwich-on-transparent-background-free-png.webp'
import img8 from '../../../res/restaurant/png-clipart-fried-food-with-sliced-lemons-samosa-pakora-indian-cuisine-stuffing-kebab-butter-food-recipe.png';


export const Files1 =[
    {
        index : "1",
        filetype : "img",
        filepath : img1
    },
    {
        index : "2",
        filetype : "img",
        filepath : img2
    },
    {
        index : "3",
        filetype : "video",
        filepath : "https://www.youtube.com/embed/ybEtf0Mwb6U"
    },
    {
        index : "4",
        filetype : "img",
        filepath : img4
    },
    {
        index : "5",
        filetype : "video",
        filepath : "https://www.youtube.com/embed/WTPComBPqsw"
    },
    {
        index : "6",
        filetype : "img",
        filepath : img6
    },
    {
        index : "7",
        filetype : "img",
        filepath : img7
    },
    {
        index : "8",
        filetype : "img",
        filepath : img8
    },
]