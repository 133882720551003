
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Sante from "../icons/sante.png";
import Building from "../icons/building.png";
import Mbi from "../icons/mbi.png";
import Mawa from "../icons/mawa.png";



export default class PauseOnHover extends Component {
    render() {
      var settings = {
        dots: true,
        infinite: true,
        speed: 1100,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              autoplaySpeed: 4000
            }
          },
          {
            breakpoint: 780,
            settings: {
              autoplaySpeed: 5000
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplaySpeed: 3000,
            }
          }
        ]
      };
    return (
      
        <div className='w-6/6 xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
            <div className='w-full md:h-full md:mt-5 mt-5 bg-primary text-black rounded-none md:rounded pb-5 md:pb-5  justify-center items-center flex-col'>
             <div className='lg:py-10 md:py-8 py'>
                 <h2 className=" lg:py-10 md:py-8 mb-8 text-white  text-2xl md:text-xl text-sm text-center font-bold">Ecoles du Groupe Mawa</h2>
                </div>
        <Slider {...settings} className="absolute top-2 md:-top-8 lg:-top-20 bg-secondary">
          <Link to="../Travaux" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Mbi} alt=""/>
              <h3 className=" group-hover:hidden md:text-sm">Mbi</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="../Travaux" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-500/5 rounded h-full flex items-center justify-start pl-10">
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Sante}/>
              <h3 className=" group-hover:hidden md:text-sm">Sante</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="../Travaux" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Building}/>
              <h3 className=" group-hover:hidden md:text-sm">Building</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="../presentation/ecoles" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={ Mawa } color="red"/>
              <h3 className=" group-hover:hidden md:text-sm">Mawa</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          </Slider>
        </div> 
        </div> 
       
    );
  }
}