import React from 'react'
import imageServiceTechnique from '../../../res/03/IMG_9455-300x200.jpg'
import imageServiceTechnique1 from '../../../res/03/10.png'
import { Link } from 'react-router-dom';

const AutreArticle = () => {
    return (
      <div>
      <div className="w-sreen xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center">
      <div className='w-full md:h-fit md:mt-5 mt-1 mb-5 bg-white text-black rounded pt-4 p-0 md:p-8 '>
          <div className='py-6'>
              <h2 className="md:text-xl text-sm text-left font-bold"><span className='text-primary font-black'>|</span>&nbsp;&nbsp;Article Similaire</h2>
          </div>
          <div className='w-full md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-4 md:grid-cols-3 md:gap-x-8 md:gap-y-8'>
              <Link state={{page : 0}} to={"../actualite"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                  <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                      <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                      <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                          <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500 '>Jeux de concours les Etoiles de Mawa (Couture)</p>
                          <p className='hidden group-hover:block group-hover:mt-2 text-white text-center leading-5 text-[0.4rem] md:text-[0.7rem] font-medium'></p>
                      </div>
                  </div>
              </Link>
              <Link state={{page : 0}} to={"../concours"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                  <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                      <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique1} alt="" />
                      <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                          <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500 '>Jeux de concours les Etoiles de Mawa (Coiffure)</p>
                          <p className='hidden group-hover:block group-hover:mt-2 text-white text-center leading-5 text-[0.4rem] md:text-[0.7rem] font-medium'>  </p>
                      </div>
                  </div>
              </Link>
              <Link state={{page : 0}} to={"#"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                  <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                      <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                      <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                          <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500 underline decoration-wavy'>le Groupe Mawa fête ses 10 ans</p>
                          <p className='hidden group-hover:block group-hover:mt-2 text-white text-center leading-5 text-[0.4rem] md:text-[0.7rem] font-medium'>Du lundi au samedi de 8h à 19h <br /> De 19h à 21h et les week-end de 8h à 21h pour les professionnels </p>
                      </div>
                  </div>
              </Link>
              <Link state={{page : 0}} to={"#"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                  <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                      <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                      <div className='group absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 md:px-6 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                          <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium duration-500 underline decoration-wavy'>Fournitures</p>
                          <p className='hidden group-hover:block group-hover:mt-2 text-white text-center leading-5 text-[0.4rem] md:text-[0.7rem] font-medium'>Coiffure : 55000 FCFA <br /> Coiffure : 20000 FCFA</p>
                      </div>
                  </div>
              </Link>
          </div>
      </div>
  </div>
</div>
)
}

export default AutreArticle