import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/25_Plan-de-travail-1-2048x593.png';
import { Link, } from 'react-router-dom';
import imageServiceTechnique from '../../../../res/images/bureau.jpg'
import imageServiceTechnique1 from '../../../../res/02/receptionniste-gouvernante-1-768x392.jpg'
import imageServiceTechnique2 from '../../../../res/02/04-7.jpg'
import imageServiceTechnique3 from '../../../../res/02/bienvenue-dans-mon-atelier-de-mode-couturier-africain-photos-et-images-de-collection.jpg'
import imageServiceTechnique4 from '../../../../res/02/image-gros-plan-estheticienne-appliquant-masque-argile-purifiant-minimisant-pores-visage-belle-jeune-femme-noire_274689-14620.jpg'
import imageServiceTechnique5 from '../../../../res/04/gestion hotellerie.jpg'
import imageServiceTechnique6 from '../../../../res/images/cuisine.jpg'
import imageServiceTechnique7 from '../../../../res/02/African-fashion-designer-creating-new-products-in-factory-and-using-laptop.jpg'
import imageServiceTechnique8 from '../../../../res/04/tourisme-en-Afrique-du-Sud.png'
import imageServiceTechnique9 from '../../../../res/04/img/hotelleri valet fille-de-chambre.jpg'


const Accueil = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Nos filières"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
        <div className="w-sreen xl:px-6 flex justify-center items-center flex-col font-normal text-xs snap-center ">
            <div className='w-full md:h-fit md:mt-10 mt-20 bg-white text-black rounded pt-16 p-15 md:p-10'>
                <div className='w-full md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-4 md:grid-cols-3 md:gap-x-8 md:gap-y-8'>
                    <Link state={{page : 0}} to={"/formations/carte/details"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-medium'>Formation à la carte</p>
                            </div>
                        </div>
                    </Link>
                    <Link state={{page : 1}} to={"/formations/receptionniste"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden rounded'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique1} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-medium'>Réceptionniste - gouvernante</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 2}} to={"/formations/coiffure"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique2} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Coiffure</p>
                            </div>
                        </div>
                    </Link> 
                    <Link  state={{page :3 }} to={"/formations/couture"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique3} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Couture - Modélisme</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 4}} to={"/formations/esthetique"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique4} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Esthétique</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 5}} to={"/formations/gestion"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique5} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Gestion hôtelière</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 6}} to={"/formations/hotel"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique6} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Hôtellerie et restauration</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 7}} to={"/formations/stylisme"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique7} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Stylisme</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 8}} to={"/formations/tourisme"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique8} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Tourisme</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 9}} to={"/formations/chambre"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique9} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Vallet - fille de chambre</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Accueil