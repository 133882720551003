import React from 'react'
import {datacham} from './datacham';
import iconArticle from '../../../../res/images/article.png';

const Accelereecham = () => {

    

  return (
    <div>
       
        <accelereecham className="w-sreen xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center">
            <div className='w-full md:h-fit md:mt-0 mt-1 bg-white text-black rounded pt-4 px-2 md:p-16'>
                <div className="w-full">
                    {datacham.map((tab, idx) => (
                        <div key={idx} className="lg:w-full mb-4 bg-slate-100/90 border border-solid p-6 border-slate-300 rounded">
                            <div className="w-full flex items-start">
                                <div className="w-full flex items-start justify-start md:mr-8">
                                    <div className="w-full md:pl-6 flex items-start justify-start flex-col">
                                        <div className='flex items-center mb-4 pb-4 border-b border-solid border-slate-200 w-full'>
                                            <img src={iconArticle} alt className="w-6 md:w-6 mr-4" />
                                            <p className="text-[0.8rem] md:text-[1rem] font-semibold leading-5 text-gray-800">Formation Accélérée &nbsp;&nbsp;<span className='text-primary'></span></p>
                                            <span className='px-4 text-slate-500 font-thin'>|</span>
                                            <p className="text-[0.5rem] md:text-xs font-light leading-normal text-gray-500">{tab.Durée}</p>
                                        </div>
                                        <p className='text-left text-[0.6rem] md:text-[0.9rem] leading-[1.5rem]'>{tab.Niveau}</p>
                                        <p className='text-left text-[0.6rem] md:text-[0.9rem] leading-[1.5rem]'>{tab.Inscription}</p>
                                        <p className='text-left text-[0.6rem] md:text-[0.9rem] leading-[1.5rem]'>{tab.Mensualité}</p>
                                        <a className='rounded p-3 px-6 mt-4 bg-primary-light text-black text-[0.6rem] md:text-xs font-medium flex justify-center items-center duration-150 hover:px-10 hover:bg-primary hover:text-white'  href='/contact/preinscription'>Se préinscrire</a>
                                        
                                    </div>
                                </div>
                                <svg className='md:visible invisible' width={20} height={20} viewBox="0 0 28 28" fill="#A3BF2D" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5001 4.66667H17.5001C18.1189 4.66667 18.7124 4.9125 19.15 5.35009C19.5876 5.78767 19.8334 6.38117 19.8334 7V23.3333L14.0001 19.8333L8.16675 23.3333V7C8.16675 6.38117 8.41258 5.78767 8.85017 5.35009C9.28775 4.9125 9.88124 4.66667 10.5001 4.66667Z" stroke="#2C3E50" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </accelereecham>
       
    </div>
  )
}

export default Accelereecham