import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/04/img/20200909-5f58da8600a0e.jpeg';
import imageServiceTechnique from '../../../../res/formations/HOTELLERIE.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelerehot from './accelerehot';
import Comple from "../comple";


const Hotel = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Hôtellerie et Restauration"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className="md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16 ">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
         Les objectifs de la formation en hôtellerie et restauration peuvent varier en fonction du type de programme et des besoins individuels, mais en général, ils incluent les éléments suivants :<br />
         <span class="text-semi-bold">-</span> Service à la clientèle : L'un des éléments essentiels de l'industrie de l'hôtellerie et de la restauration est le service à la clientèle de qualité. La formation vise à enseigner aux étudiants comment offrir un excellent service, gérer les plaintes des clients et créer des expériences mémorables pour les clients. <br />
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         Une formation en hôtellerie et restauration offre de nombreux débouchés professionnels dans l'industrie de l'accueil, de la restauration et du tourisme. Voici quelques-uns des débouchés professionnels courants :<br />
         1- Chef cuisinier<br />
         2- Gestionnaire d'hôtel<br />
         3- Responsable de la restauration<br />
         4- Responsable de la réception <br />
             </p>
             <a href={document} download className='absolute mt-8 ml-64 text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Accelerehot />
    <Comple />
    <Footer />
</div>
    
  )
 
}

export default Hotel