import React from 'react'
import Header from './components/elements/header';
import Menu from './components/elements/menu/menu';

const Travaux = () => {
  return (
    <div>
      <Header/>
      <Menu/>
      <div className='w-full h-screen md:h-auto flex justify-center items-center xl:px-8 text-[0.6rem] md:text-[0.8rem]'>
        <div className='w-full h-full flex justify-center items-center flex-col relative md:mt-2 p-12 md:p-40 bg-primary-light rounded'>
          <h1 className='font-bold text-7xl md:text-9xl mb-10 md:mb-12'>Oops !</h1>
          <h1 className='text-[1rem] md:text-4xl text-white md:text-secondary font-bold mb-10 md:mb-12'>Cette page est indisponible !</h1>
          <p className='text-[0.6rem] md:text-xl text-primary md:text-secondary'>Des travaux sont actuellement en cours . . .</p>
        </div>
      </div>
    </div>
  )
}

export default Travaux