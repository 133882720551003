import React from 'react';
import Hero from './hero';
import Presentation from '../presentation';
import News from '../news';
import Contacts from '../contacts';
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Partage from '../../../elements/partage';
import Carousel from './Carousel';




const Ecoles = () => {
    return(
        <div>
            <Header/>
            <Menu/>
            <Hero/>
            <Presentation/>
            <Carousel/>
            <News/>
            <Contacts/>
            <Partage />
            <Footer/>
        </div>
    )
}
export default Ecoles;