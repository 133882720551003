import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/etudiant-noir-52810226-768x512.jpg';
import { Link } from 'react-router-dom';
import imageServiceTechnique from '../../../../res/02/carte/African-fashion-designer-creating-new-products-in-factory-and-using-laptop.jpg'
import imageServiceTechnique1 from '../../../../res/02/carte/divers-createurs-de-mode-masculins-et-feminins-au-travail-discutant-et-regardant-le-tissu-couturi.jpg'
import imageServiceTechnique2 from '../../../../res/02/carte/Female-chef-cooking-at-a-restaurant-283x300.jpg'
import Partage from '../../../elements/partage';
import Institut from '../../../elements/institut/institut';


const Carte = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Formations à la carte"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
        <div className="w-screen ml-2 mr-12  xl:px-6 flex justify-center items-center flex-col font-normal text-xs snap-center ">
            <div className='w-fit mb-5 md:h-fit md:mt-5 mt-20 bg-white text-black rounded pt-16 p-15 md:p-10'>
                <div className=' mr-8 md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-3 md:grid-cols-3 md:gap-x-8 md:gap-y-8'>
                    <Link state={{page : 0}} to={"/formations/carte/details"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] overflow-hidden h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-medium'>Coiffure et Esthétique</p>
                            </div>
                        </div>
                    </Link>
                    <Link state={{page : 1}} to={"/formations/carte/details"}  className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden rounded'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique1} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-medium'>Couture et Stylisme</p>
                            </div>
                        </div>
                    </Link>
                    <Link  state={{page : 2}} to={"/formations/carte/details"} className='w-full flex justify-center px-4 pb-4 md:p-0 overflow-hidden'>
                        <div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain flex justify-center items-center relative rounded md:hover:scale-110 duration-300'>
                            <img className='h-full group-hover:scale-150 duration-1000' src={imageServiceTechnique2} alt="" />
                            <div className='absolute bottom-0 group-hover:h-full group-hover:backdrop-blur-sm bg-gradient-to-t group-hover:from-primary/75  group-hover:to-primary from-secondary-dark/50 to-secondary-dark/50 group-hover:rounded duration-500 w-full h-fit px-4 py-6 rounded-b flex justify-end group-hover:justify-center items-center flex-col'>
                                <p className='text-white text-center leading-6 text-xs md:text-[0.9rem] font-medium'>Restauration</p>
                            </div>
                        </div>
                    </Link> 
                </div>
            </div>
        </div>
        <Partage />
        <Footer/>
        <Institut />
    </div>
  )
}

export default Carte