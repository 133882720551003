export const sliderData = [
  {
    image: 1,
    heading: "MW RESTAURANT & TRAITEUR ",
    description: "Saveurs d'exception, moments mémorables : Votre festin, notre passion !",
  },
  {
    image: 2,
    heading: "MAWA BOUTIQUE SHOPPING",
    description: "Style qui séduit, choix qui inspire : Vivez la mode avec élégance, explorez votre unicité chez nous !",
  },
  {
    image: 3,
    heading: "COMPLEXE MAWA",
    description: "Éveillez le savoir, forgez l'avenir : Apprendre, Grandir, Réussir ensemble !",
  },
  {
    image: 4,
    heading: "MASSALIKUL JINAAN STORE",
    description: " Connectez-vous à l'innovation, vibrez avec la technologie : Votre source électrifiante de découvertes numériques!",
  },
];
