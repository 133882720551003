export const Data = [

    {
        index:"0000",
        image:"img1",
        title: "Coiffure et Esthétique",
        mission: "La coiffure est une discipline artisanale liée aux mondes de la mode et de la beauté, mais c’est avant tout un savoir-faire technique et artistique de grande qualité, qui nécessite une formation complète.le titulaire du CAP Coiffure se verra confier différentes tâches qui participent à la réalisation d'une coupe, d'une coloration sous la surveillance d'un responsable, d'une permanente. Il sera, suivant les circonstances, technicien du cheveu, conseiller et vendeur de produits et de services.",
        activites: "Make-up, Prothésiste angulaire, Extention cil-à-cil, Massage",
        projets:"Coutumier, Patronnier, Créateur textile, Coupeur textile, Chapelier - modiste, Créateur de mode, Couture, etc ...",
    },
    {
        index:"0000",
        image:"img2",
        title: "Couture et Stylisme",
        mission: "Cette formation va vous permettre, via les connaissances acquises et des techniques sous-jacentes que vous maîtriserez, de coudre et confectionner n'importe quel type de vôtement du vestiaire féminin/masculin. En plus de connaissances précises dans la création de tout ou partie d'un vêtement et la réalisation de travaux de retouches vous obtenez un diplôme d'Etat, officiel et reconnu qui atteste de votre maîtrise de la couture.",
        activites: "Coupe, Couture, Design, Finition, Broderie",
        projets:"Costumier, Patronnier, Créateur textile, Coupeur textile, Chapelier - modiste, Créateur de mode, Couture, etc...",
    },
    {
        index:"0000",
        image:"img3",
        title: "Restauration",
        mission: "Réaliser les techniques préliminaires. Cuisiner des produits et des spécialités caractéristiques d'une région ou d'un territoire. Contrôler la qualité gustative tout au long de la production. Adapter sa tenue et son attitude aux codes professionnels de l'entreprise. Rechercher et utiliser les informations économiques, sociales et juridiques au secteur professionnel. Communiquer avec les clients, les tiers.",
        activites: "C'est alors un professionnel quélifié et compétent pour la réalisation de plats nécessitant l'utilisation de différentes techniques de production culinaire il connait les produits alimentaires dont il gère l'approvisionnement (bons de commandes, stockage des marchandises, calcul des coûts). Il peut exercer:dans le domaine de la restauration commerciale, deans le domaine de la restauration collective ou dans les entreprises connexes à l'insdustrie hôtelière",
        projets:"Pâtissereie, Boulangerie, Nutrition, Serveur, Caisse, Bar.",
    },
    
  ];
  