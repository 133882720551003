import React from "react";
import Header from "../../elements/header";
import Footer from "../../elements/footer";
import Menu from "../../elements/menu/menu";
import VideoSpot from './video/videoSpot'
import imageServiceTechnique from '../../../res/8mars/IMG-20230309-WA0095.jpg';
import Similaire from "./AutreArticle";



const Actualite = () => {
    return(
        <div>
            <Header/>
             <Menu />
            <div className='w-6/7 xl:px-8 mb-0 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-0 mt-1 bg-white text-black  pb-10 md:pb-28'>
                    <div className='w-6/6 mt-0flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                       <img className="w-screen h-auto" src= { imageServiceTechnique } alt="" />   
                    </div>
                 </div>         
            </div> 
            <div className='w-6/7  xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-1 mt-1 bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                    <div className='lg:py-10 md:py-8 py'>
                        <h1 className="mt-0 lg:text-2xl md:text-xl text-sm text-center font-bold">Célèbration de la journée internationale de la femme</h1>
                        <p className="mt-5 ml-36 mr-36 lg:text-[0.9rem] md:text-[0.9rem] text-base text-justify flex justify-center items-center md:items-start flex-col font-semi-bold">La journée internationale de la femme était un moment particulièrement spécial pour le groupe Mawa. Sous la direction inspirante de leur directrice dévouée, les femmes du groupe Mawa ont organisé une journée porte ouverte remplie d'encouragements et de motivations.<br /> La directrice, véritable force motrice derrière l'événement, a prononcé un discours passionné par l'importance de célébrer les réalisations des femmes et de soutenir leur croissance continue. Elle a souligné la puissance collective des femmes du groupe Mawa et a encouragé chacune d'entre elles à poursuivre leurs rêves avec détermination.<br />L'énergie positive et l'atmosphère motivante de la journée ont créé un espace où les femmes du groupe Mawa ont pu partager leurs expériences, inspirer les autres et renforcer leur solidarité. Cette journée spéciale a été bien plus qu'une simple célébration ; elle a été le reflet de l'unité, de la résilience et de la force des femmes au sein du groupe Mawa. </p>   
                     </div> 
                     <div className='w-6/6 ml-36 mr-36 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                        <VideoSpot /> 
                     </div>
                     
                </div>   
            </div>
            <Similaire />
            <Footer/>
        </div>
    )
}
export default Actualite;