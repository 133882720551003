import React from "react";
import { useState } from "react";
import Users from './cards';
import Pagination from './Pagination';
import {Files1} from "./files1";

function List1() {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  function prevPage(){
    if (page===1) {
      setPage(totalPages);
    }else{
      setPage(page-1);
    }
  }
  function handleClick(num) {
    setPage(num);
  }
  function nextPage(){
    if (page===totalPages) {
      setPage(1);
    }else{
      setPage(page+1);
    }
  }
  
  return (
    <div className='w-full flex justify-center items-center flex-col font-normal lg:text-sm text-xs'>
      <div className='w-[100%]  flex items-center flex-col bg-white text-black'>
        <div className="text-sm mb-4 text-black font-normal w-full overflow-x-auto">
            <Users users={Files1} page={page} />
        </div>
        <Pagination totalPages={totalPages} handleClick={handleClick} prevPage={prevPage} nextPage={nextPage} />
      </div>
    </div>
  );
}

export default List1;
