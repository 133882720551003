import React from 'react'
import './partage.css';

const Partage = () => {


    return (
        
    <div className='w-6/6 ml-8 mt-4 mb-4 mr-8 bg-primary'>
        <h2 className="lg:text-2xl ml-72 md:text-xl text-sm flex  font-bold text-white "> Nous Contacter ! </h2>
<div className=' md:h-12 lg:h-16 h-screen hidden relative md:flex md:justify-center md:items-center  text-black md:shadow-xl lg:rounded-none'>
<div className='w-full ml-64 flex absolute md:relative z-40 md:h-16 h-screen md:flex md:justify-center md:items-center backdrop-blur-md  backdrop-brightness-50 bg-white/25 md:bg-primary text-white md:shadow-xl'>
                    <ul className='menu w-fit  mt-12 ml-24 md:mt-0 flex justify-start items-center flex-col md:justify-center space-x-8 md:items-center md:flex-row bg- md:bg-none'>
                    <li><a className='' href='https://www.facebook.com/people/Groupe-Mawa/100063651647162/'><button className='h-10 w-32 rounded bg-secondary text-white  font-semi-bold flex justify-center items-center duration-150  hover:bg-primary hover:px-10 hover:text-white shadow-gray-100'>Facebook</button></a></li>
                    <li ><a className='' href='https://twitter.com/groupemawa?lang=fr'><button className='h-10 w-32 rounded bg-secondary text-white font-semi-bold flex justify-center items-center duration-150  hover:bg-primary hover:px-10 hover:text-white shadow-gray-100'>Twitter</button></a></li>
                    <li><a className='' href='https://www.linkedin.com/in/groupe-mawa-1095621b4/?originalSubdomain=sn'><button className='h-10 w-32 rounded bg-secondary text-white font-semi-bold flex justify-center items-center duration-150  hover:bg-primary hover:px-10 hover:text-white shadow-gray-100'>LinkedIn</button></a></li>
                    </ul>
        </div>
</div>
</div>
    )
}

export default Partage