import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/braid-african-2218520_1920.jpg';
import { Link, Outlet } from 'react-router-dom';
import imageServiceTechnique from '../../../../res/formations/coiffure_page-0001.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelereecoif from './accelereecoif';
import Comple from "../comple";

const Coiffure = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Coiffure"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className="md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16">
          <img className="h-25" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
           La coiffure est une discipline artisanale liée aux mondes de la
           mode et de la beauté,mais c’est avant tout un savoir-faire technique et artistique de grande qualité, 
           qui nécessite une formation complète.<br />
           Le titulaire du CAP Coiffure se verra confier différentes tâches qui participent à la réalisation d’une coupe, 
           d’une coloration sous la surveillance d’un responsable, d’une permanente. Il sera, suivant les circonstances,
           technicien du cheveu, conseiller et vendeur de produits et de services.
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         La coiffure est un domaine professionnel qui offre divers débouchés aux individus formés en coiffure. Voici quelques-uns des débouchés professionnels en coiffure :<br />
           1. Coiffeur en salon <br />
           2. Styliste de mariage <br />
           3. Styliste de mode <br />
           4. Coiffeur à domicile <br />
           5. Formateur en coiffure <br />
           6. Coiffeur dans l'industrie du cinéma et de la télévision <br />
           7. Coiffeur pour célébrités
          </p>
             <a href={document} download className='absolute mt-8 ml-64  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Accelereecoif />
    <Comple />
    <Footer />
</div>
    
  )
 
}

export default Coiffure