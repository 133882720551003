import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/students_bg-fi34434689x2000.jpg';
import img1 from '../../../../res/images/testord2_Plan-de-travail-1-1024x726.png';
import img2 from '../../../../res/images/COUT-_-STYL_Plan-de-travail-1-768x545.png';
import img3 from '../../../../res/images/HOTELLERIE-ORDINATEUR_Plan-de-travail-1.png';
import img4 from '../../../../res/images/blank.jpg';
import img5 from '../../../../res/images/blank.jpg';
import Tab from "./Tab";
import { Data } from './data';
import { useLocation } from "react-router";
import Comple from "../comple";

const CarteDetails = () => {
    
    const { state } = useLocation()
    console.log(state.page)

  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Formations à la carte > Détails"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
        <div className='w-full xl:px-8 flex justify-center items-center flex-col font-normal lg:text-sm text-xs'>
            <div className='w-[100%] flex items-center flex-col  lg:p-8 md:p-4 px-2 py-4 bg-white text-black rounded'>
                <div className="w-full h-full text-black">
                    <Tab   state={state.page}>
                        {Data.map((tab, index) => {
                            return (
                                <Tab.TabPane key={`Tab-${tab.index}`} tab={tab.title}>
                                    <div className="w-full h-full rounded  text-[0.6rem] md:text-[0.8rem]">
                                        <div className='w-full h-100 bg-gray-100 mb-2 md:mb-4 rounded overflow-hidden flex items-center justify-center'>
                                            <img src={tab.image==="img1"?img1:(tab.image==="img2"?img2:(tab.image==="img3"?img3:(tab.image==="img4"?img4:img5)))} alt="" className='w-full h-auto'/>
                                        </div>
                                        <div className='h-20 flex justify-center items-center '>
                                        <a className='shadow-lg' href=""><div className='md:w-fit w-full md:max-w-none max-w-[330px] md:h-12 h-10 px-8 md:mt-4 mt-4 rounded-full bg-primary text-white md:text-[0.7rem] text-[0.6rem] font-bold flex justify-center items-center duration-150 hover:px-10 hover:bg-primary hover:text-white'>Voir la brochure</div></a>
                                        </div>

                                        
                                        
                                        <p className='md:py-4 py-2 px-4 md:px-8 w-full bg-secondary text-xs font-semibold rounded-t text-[0.6rem] md:text-[1rem] md:font-medium text-white'>Objectifs</p>
                                        <div className='bg-gray-100 rounded-b mb-2 md:mb-4 p-4 md:p-8 leading-8 text-[1.0rem] flex justify-center flex-col'>{tab.mission}</div>
                                        <p className='md:py-4 py-2 px-4 md:px-8 w-full bg-secondary text-xs font-semibold rounded-t text-[0.6rem] md:text-[1rem] md:font-medium text-white'>Modules</p>
                                        <div className='bg-gray-100 rounded-b mb-2 md:mb-4 p-4 md:p-8 leading-7 text-[1.0rem] flex justify-center flex-col '>{tab.activites}</div>
                                        <p className='md:py-4 py-2 px-4 md:px-8 w-full bg-secondary text-xs font-semibold rounded-t text-[0.6rem] md:text-[1rem] md:font-medium text-white'>Débouchés</p>
                                        <div className='bg-gray-100 rounded-b p-4 md:p-8 leading-7 text-[1.0rem] flex justify-center flex-col '>{tab.projets}</div>
                                    </div>
                                </Tab.TabPane>
                            );
                        })}
                    </Tab>
                </div>
            </div>
        </div>
        <Comple />
        <Footer/>
    </div>
  )
}

export default CarteDetails