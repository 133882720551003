import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/billet-couture-1024x671.jpg';
import imageServiceTechnique from '../../../../res/formations/couture-modelisme.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Acceleree from './acceleree';
import Comple from '../comple';

const Couture = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Couture - Modéliste"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className="md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16 ">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
         La formation en couture modélisme a plusieurs objectifs, qui peuvent varier en fonction du niveau de compétence visé et du programme spécifique de la formation. Voici quelques objectifs courants de la formation en couture :<br />
         <span class="text-semi-bold"> - </span> Acquérir des compétences de base: Pour les débutants, l'objectif principal est d'apprendre les compétences de base en couture, y compris la manipulation de la machine à coudre, la coupe de tissu, la couture à la main, et la lecture de patrons.<br />
         <span class="text-semi-bold"> - </span> Maîtriser les techniques de couture: Les formations en couture visent à enseigner diverses techniques de couture, telles que la pose de fermetures à glissière, la réalisation de boutonnières, la couture de biais, la réalisation de coutures solides et d'autres compétences avancées.
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         Une formation en couture modélisme peut ouvrir la voie à divers débouchés professionnels, en fonction du niveau de compétence, de l'expérience et des aspirations de la personne. Voici quelques débouchés professionnels courants en couture :<br />
         1. Styliste de mode <br />
         2. Couturier/couturière <br />
         3. Modéliste <br />
         4. Technicien(ne) de la mode <br />
         5. Enseignant(e) de couture <br />
         6. Entrepreneur(e) en couture <br />
         7. Designer de costumes

             </p>
             <a href={document} download className='absolute mt-8 ml-60  text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Acceleree />
    <Comple />
    <Footer />
</div>
    
  )
 
}

export default Couture