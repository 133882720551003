import React from 'react';
import About from "./About";
import Equipe from "./equipe";
import imageServiceTechnique1 from "../../../res/restaurant/riz.jpg";
import imageServiceTechnique2 from "../../../res/restaurant/riz-v.jpg";
import imageServiceTechnique3 from "../../../res/restaurant/riz-vv.jpg";
import imageServiceTechnique4 from "../../../res/restaurant/rizzz.jpg";
import imageServiceTechnique5 from "../../../res/restaurant/soup.jpg";
import imageServiceTechnique6 from "../../../res/restaurant/faaa.jpg";
import imageServiceTechnique7  from "../../../res/restaurant/faaa.jpg";
import imageServiceTechnique8  from "../../../res/restaurant/kaa.jpg";
import imageServiceTechnique9  from "../../../res/restaurant/kaldou.jpg";
import imageServiceTechnique10  from "../../../res/restaurant/mafe.jpg";
import imageServiceTechnique11  from "../../../res/restaurant/souu.jpg";
import imageServiceTechnique12 from "../../../res/restaurant/cbon.jpg";
import imageServiceTechnique13  from "../../../res/restaurant/dakhine.jpg";
import imageServiceTechnique14  from "../../../res/restaurant/bu.jpg";
import imageServiceTechnique15  from "../../../res/restaurant/chan.jpg";
import imageServiceTechnique16  from "../../../res/restaurant/couss.jpg";
import imageServiceTechnique17  from "../../../res/restaurant/thioooo.jpg";
import imageServiceTechnique18  from "../../../res/restaurant/fanems.jpg";
import imageServiceTechnique19  from "../../../res/restaurant/tacos.jpg";
import imageServiceTechnique20  from "../../../res/restaurant/nemsss.jpg";
import imageServiceTechnique21  from "../../../res/restaurant/omelee.jpg";
import imageServiceTechnique22  from "../../../res/restaurant/omelette.jpg";
import imageServiceTechnique23 from "../../../res/restaurant/poul-cui.jpg";
import imageServiceTechnique24  from "../../../res/restaurant/poulettt.jpg";

const menuResto = () => {
    return(
        <div>
             <div className="w-sreen xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center">
                <div className='w-full xl:px-8 flex justify-center items-center flex-col text-lg font-thin'>
                            <div className='w-[100%] pb-8 md:pb-16 lg:pb-20 xl:px-8 md:mt-2 mt-1 bg-white text-black rounded flex justify-start items-center flex-col'>
                                <div className='lg:py-20 md:py-12 py-10 font-bold lg:text-2xl md:text-xl text-sm'> Plats </div>
                 <div className='w-4/5 md:w-auto flex justify-center items-center flex-col md:grid lg:grid-cols-4 md:grid-cols-2 md:gap-x-8 md:gap-y-8'>
                                <a href="./galerie/Travaux" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique1} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Vermicelle Pouet</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                            <img className='w-4/5 duration-1000' src={imageServiceTechnique2} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Thiebou Dieune Djiagua</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                              <img className='w-4/5 duration-1000' src={imageServiceTechnique3} alt="" />
                               <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Thiebou yapp</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                                 <img className='w-4/5 duration-1000' src={imageServiceTechnique4} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Thiébou Ganar</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                                 <img className='w-4/5 duration-1000' src={imageServiceTechnique5} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Thiébou Djieune Yass</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-full max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique6} alt="" />
                            <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Soupou Kandia </p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique7} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Fataya </p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique8} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Kaldou</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique9} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Kaldou</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique10} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Mafé</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique11} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Soupe Kandia</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique12} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>C'Bon</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique13} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Dakhine</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique14} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Burger viande / Poulet et Mixte</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique15} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Shandich Viande / Poulet Mixte</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique16} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Couscous Marocain</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique17} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Thiof</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique18} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Fataya & Nems</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique19} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Tacos</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded'>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique20} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Nems</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique21} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Omelette</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique22} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Omelette</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique23} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Poulet Pané</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>
                        <a href="/contact/preinscription" className='w-full flex justify-center px-4 pb-4 md:p-0'><div className='group w-4/5 max-w-[350px] md:w-[310px] h-[320px] bg-contain bg-secondary flex justify-center items-center relative rounded '>
                                <img className='w-4/5 duration-1000' src={imageServiceTechnique24} alt="" />
                                <div className='absolute bottom-0  duration-300 w-full h-20 px-8 bg-primary rounded-b flex justify-center items-center flex-col'>
                                <p className='text-white text-xs md:text-[0.9rem] font-semibold'>Poulet</p>
                                <a className='text-black' href=""><div className='mt-4 md:h-8 h-4 md:px-2 px-0 rounded-br-lg bg-secondary text-white font-bold flex justify-center items-center duration-150 hover:px-4 hover:bg-primary hover:text-white'>Commander</div></a>
                            </div>
                        </div></a>

                    </div>
                </div>
            </div>
    </div>
    <About />
    <Equipe />
    </div>
        )
        }
        export default menuResto;