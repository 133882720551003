import React from 'react'
import Header from '../../elements/header';
import Footer from '../../elements/footer';
import Menu from '../../elements/menu/menu';
import Page_info from '../../elements/page_info';
import bg from '../../../res/03/IMG_1900-1024x683.jpg';
import Tab from "./Tab";
import { Data } from './data';
import { useLocation } from "react-router";
import List2 from './list2';

const MediaDetails2 = () => {
    
    const { state } = useLocation()
    console.log(state.page)

  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Médiathèque"} link={"Médiathèque > Album > Détails"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
        <div className='w-full xl:px-8 flex justify-center items-center flex-col font-normal lg:text-sm text-xs'>
            <div className='w-[100%] flex items-center flex-col  lg:p-8 md:p-4 px-2 py-4 bg-white text-black rounded'>
                <div className="w-full h-full text-black">
                    <Tab   state={state.page}>
                        {Data.map((tab, index) => {
                            return (
                                <Tab.TabPane key={`Tab-${tab.index}`} tab={tab.album}>
                                    <div className="w-full h-full rounded text-[0.6rem] md:text-[0.8rem]">
                                        <List2/>
                                    </div>
                                </Tab.TabPane>
                            );
                        })}
                    </Tab>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default MediaDetails2