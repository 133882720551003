import React from 'react';
import { FaClock, FaFacebookMessenger, FaFacebookSquare, FaLinkedin,  FaPhone,  FaTiktok, FaStar,  FaYoutubeSquare } from 'react-icons/fa';

const Footer = () => {
    return(
        <div>
            <footer className='w-full xl:px-8 flex justify-center items-center flex-col font-medium xl:text-lg'>
                <div className='w-[100%] md:mt-0 mt-0 bg-primary text-black xl:rounded-t'>
                    <div className='w-full md:h-fit md:py-8  grid grid-cols-2 md:grid-cols-4 text-white text-[0.6rem] md:text-[0.7rem] lg:text-xs xl:text-sm font-light place-items-start gap-2'>
                        <div className='w-full h-52 md:h-auto flex md:pl-20 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Informations</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaClock size={9}/><span className='ml-2 md:ml-4'>Lundi au Samedi de 8h à 18h30</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 77 656 51 96 </span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 78 135 22 57</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaFacebookMessenger size={9}/><span className='ml-2 md:ml-4'>mawaequipe@gmail.com</span></a>
                        </div>
                        <div className='w-full h-52 md:h-auto flex md:pl-12 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Sites du Groupe</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Keur Massar</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Diaxaye</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Castor</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaStar size={9}/><span className='ml-2 md:ml-4'>Sacrés Coeur Rond Point JVC</span></a>
                        </div>
                        <div className='w-full h-52 md:h-auto flex md:pl-12 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Nos Contacts</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 78 135 22 57 &nbsp;</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 77 169 35 82 &nbsp;</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 70 470 67 26 &nbsp;</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href=""><FaPhone size={9}/><span className='ml-2 md:ml-4'>+221 70 203 91 16 &nbsp;</span></a>
                        </div>
                        <div className='w-full h-52 md:h-auto flex md:pl-12 p-6 md:border-none border border-solid border-white/10 justify-center items-start md:items-start flex-col'>
                            <h3 className='py-2 md:py-4 text-xs xl:text-lg font-semibold'>Nous suivre</h3>
                            <a className='py-1 md:py-2 flex justify-center items-center' href="https://www.facebook.com/people/Groupe-Mawa/100063651647162/"><FaFacebookSquare/><span className='ml-2 md:ml-4'>Facebook</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href="https://www.linkedin.com/in/groupe-mawa-1095621b4/?originalSubdomain=sn"><FaLinkedin/><span className='ml-2 md:ml-4'>LinkedIn</span></a>
                            <a className=' py-1 md:py-2 flex justify-center items-center' href="https://www.youtube.com/channel/UCkQuw1PQTXDjgqt-TF4RQWw"><FaYoutubeSquare/><span className='ml-2 md:ml-4'>Youtube</span></a>
                            <a className='py-1 md:py-2 flex justify-center items-center' href="https://www.tiktok.com/@groupemawa"><FaTiktok/><span className='ml-2 md:ml-4'>Tiktok</span></a>
                        </div>
                    </div>
                    <div className='w-full h-[50px] bg-secondary  flex justify-center items-center text-white text-[0.6rem] md:text-xs'><p>Groupe Mawa © 2023. Tous droits réservés.</p></div>
                </div>
            </footer>
        </div>
    )
}

export default Footer;