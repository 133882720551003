import React from "react";
import Header from "../../elements/header";
import Footer from "../../elements/footer";
import Menu from "../../elements/menu/menu";
import VideoSpot from './video/ConcoursVideo'
import imageServiceTechnique from '../../../res/02/IMG_1900-1536x1024.jpg';
import Similaire from "./AutreArticle";



const Actualite = () => {
    return(
        <div>
            <Header/>
             <Menu />
            <div className='w-6/7 xl:px-8 mb-0 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-0 mt-1 bg-white text-black  pb-10 md:pb-28'>
                    <div className='w-6/6 mt-0flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                       <img className="w-screen h-auto" src= { imageServiceTechnique } alt="" />   
                    </div>
                 </div>         
            </div> 
            <div className='w-6/7  xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-1 mt-1 bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                    <div className='lg:py-10 md:py-8 py'>
                        <h1 className="mt-0 lg:text-2xl md:text-xl text-sm text-center font-bold">Jeux concours les Etoiles de Mawa</h1>
                        <p className="mt-5 ml-36 mr-36 lg:text-[0.9rem] md:text-[0.9rem] text-base text-justify flex justify-center items-center md:items-start flex-col font-semi-bold">Les Étoiles de Mawa, ça a une belle sonorité, n'est-ce pas ? Imaginons donc un concours extraordinaire qui met en avant les talents exceptionnels en couture et coiffure au sein du groupe.<br />Pour le concours de couture, chaque participant représentait une étoile brillante. Le défi consistait à créer une tenue qui capture l'éclat unique de leur étoile assignée. Les matériaux, les couleurs et les designs doivent être soigneusement choisis pour refléter la personnalité de chaque étoile de Mawa. La directrice, avec son œil expert, pourrait évaluer les créations en fonction de leur originalité, de leur exécution et de leur harmonie avec le thème stellaire.<br />En ce qui concerne le concours de coiffure, les participants auraient pour mission de créer des styles éblouissants inspirés par les constellations. Les étoiles, la lueur de la lune, tout pourrait être une source d'inspiration. Des coiffures artistiques et innovantes seraient présentées, et la directrice, en tant que juge principale, évaluerait la créativité, la technique et l'expression artistique.<br />Les Étoiles de Mawa ne sont pas seulement des compétitrices, mais des créatrices d'œuvres d'art qui célèbrent la diversité des talents au sein du groupe. À la fin de la journée, toutes les participantes brilleront ensemble, chacune apportant sa propre lumière à l'éclat collectif des Étoiles de Mawa </p>   
                     </div> 
                     <div className='w-6/6 ml-36 mr-36 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                        <VideoSpot /> 
                     </div>
                     
                </div>   
            </div>
            <Similaire />
            <Footer/>
        </div>
    )
}
export default Actualite;