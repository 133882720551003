import React from 'react';
import VideoSpot from './video/videoSpot'



const Presentation = () => {
    


    return(
        <div>
            <div className='w-6/7 xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
                <div className='w-full md:h-full md:mt-1 mt-1 bg-white text-black rounded-none md:rounded pb-10 md:pb-28'>
                    <div className='lg:py-10 md:py-8 py'>
                        <h2 className="lg:text-2xl md:text-xl text-sm text-center font-bold">Mots de Bienvenue de la Directrice</h2>
                    </div>
                    <div className='w-6/6 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
                            <VideoSpot />
                        <div className='xl:w-[600px] lg:w-[100] md:w-[100] w-full bg-white overflow-hidden md:px-0 px-2 flex justify-center items-center md:items-start flex-col '>
                            <p className='text-[0.9rem] md:text-[0.9rem] text-justify font-medium leading-5 md:leading-7 list-disc'>
                               Complexe Mawa est un institut de formation professionnelle de qualité, soutenu d'un diplôme d'école reconnu par l'Etat du Sénégal et spécialisée dans les formations professionnelles en stylisme,couture, restauration...<span className='hidden xl:block'><br />Nos formations vous ouvrent les portes de l'emploi ou mieux encore, l'auto-entreprenariat. Avec Groupe Mawa, c'est une formation accessible pour tous et la réussite est à votre portée !</span>
                            </p>
                            <a className='shadow-lg' href="./presentation/about"><div className='md:w-fit w-full md:max-w-none max-w-[330px] ml-8 mb-8 md:h-12 h-10 px-8 md:mt-4 mt-4 rounded bg-primary text-white md:text-[0.7rem] text-[0.6rem] font-bold flex justify-center items-center  duration-150 hover:px-10 hover:bg-primary hover:text-white'>Lire la suite</div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation;