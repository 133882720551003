export const sliderData = [
  {
    image: 1,
    heading: " MW RESTAURANT",
    description: "Où chaque plat est une expérience gastronomique",
  },
  {
    image: 2,
    heading: "MW RESTAURANT",
    description: "L'art de la cuisine, la passion du service",
  },
  {
    image: 3,
    heading: "MW RESTAURANT",
    description: "Le restaurant où les saveurs prennent vie",
  },
];
