import React from 'react'
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Page_info from '../../../elements/page_info';
import bg from '../../../../res/02/arton1022-768x282.png';
import imageServiceTechnique from '../../../../res/formations/MANAGEMENT HOTELIER_page-0001.jpg'
import document from '../../../../res/images/flyer-complexe-2023.pdf'
import Accelereges from './accelereges';
import Comple from "../comple";

const Gestion = () => {
  return (
    <div>
        <Header/>
        <Menu/>
        <Page_info name={"Formations"} link={"Formations > Gestion  hôtelière "} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
          <div className=" md:h-full md:mt-5 mb-5 bg-white font-serif flex justify-center space-x-12 w-fit mx-8 pt-4 px-8 md:p-16 ">
          <img className="h-30" src= { imageServiceTechnique } alt="" /> 
          
        <div className='text-black mx-4'>
        <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Objectifs de la formation </h1>
         <p class="text-justify">
         Les objectifs d'une formation en gestion hôtelière sont de préparer les étudiants à travailler avec succès dans l'industrie de l'hôtellerie et de les doter des compétences nécessaires pour gérer efficacement les opérations d'une entreprise hôtelière. Voici quelques-uns des objectifs clés d'une telle formation :<br />
         <span class="text-semi-bold">-</span> Développer des compétences en service à la clientèle : Apprendre à fournir un service exceptionnel aux clients, à gérer les plaintes, à anticiper les besoins des clients et à promouvoir la satisfaction client. <br />
         <span class="text-semi-bold">-</span> Comprendre la gestion des installations : Apprendre à gérer les infrastructures hôtelières, y compris l'entretien, la sécurité, la gestion des installations, et l'amélioration continue.
             </p>
             <h1 className='m-0 text-red-500 m-0 text-red-500 lg:text-2xl md:text-xl text-sm text-center font-bold '>Débouchés professionnels </h1>
         <p class="text-justify">
         Une formation en gestion hôtelière offre un large éventail de débouchés professionnels dans l'industrie de l'hôtellerie, de la restauration et de l'accueil. Voici quelques-uns des débouchés professionnels courants pour les diplômés en gestion hôtelière:<br />
        1- Directeur de la restauration <br />
        2- Directeur des ventes et du marketing <br />
        3- Gestionnaire de l'événementiel <br />

             </p>
             <a href={document} download className='absolute mt-8 ml-64 text-white text-end bg-red-500 rounded-lg p-2 px-10 mb-8 end-5 '>Plus de détails</a>
         </div>
              
     </div>

    <Accelereges />
    <Comple />
    <Footer />   
</div>
    
  )
 
}

export default Gestion