import React from 'react';
import ReactDOM from 'react-dom';
import Hero from './hero';
import Presentation from '../presentation';
import Projects from '../projects';
import News from '../news';
import Contacts from '../contacts';
import Header from '../../../elements/header';
import Footer from '../../../elements/footer';
import Menu from '../../../elements/menu/menu';
import Carousel from './Carousel';

const Home = () => {
    return(
        <div>
            <Header/>
            <Menu/>
            <Hero/>
            <Carousel/>
            <Presentation/>
            <Projects/>
            <News/>
            <Contacts id="contacts"/>
            <Footer/>
        </div>
    )
}
export default Home;