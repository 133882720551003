
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Sante from "../icons/mawa.png";
import Mjs from "../icons/MJS.png";
import Mw2 from "../icons/MW 2.png";
import Mawaq from "../icons/mawaq.png";
import Kalado from "../icons/kalado-prod.png";



export default class PauseOnHover extends Component {
    render() {
      var settings = {
        dots: true,
        infinite: true,
        speed: 1100,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              autoplaySpeed: 4000
            }
          },
          {
            breakpoint: 780,
            settings: {
              autoplaySpeed: 5000
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplaySpeed: 3000,
            }
          }
        ]
      };
    return (
      <div className="pb-10 px-0 md:px-20 xl:px-52 relative md:h-36 lg:h-50 text-[0.8rem] md:text-[1rem] lg:text-[1.1rem] text-gray-800 font-semibold">
        <Slider {...settings} className="absolute top-2 md:-top-10 lg:-top-20">
          <Link to="./presentation/ecoles" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="absolute top-0 left-[-20%] md:w-52 h-52 opacity-5" src={Sante}/>
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Sante}/>
              <h3 className=" group-hover:hidden md:text-sm">COMPLEXE MAWA</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="/Travaux" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="absolute top-0 left-[-20%] md:w-52 h-52 opacity-5" src={Mjs}/>
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Mjs}/>
              <h3 className=" group-hover:hidden md:text-sm">MASSALIKUL JINAAN STORE</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="/galerie" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="absolute top-0 left-[-20%] md:w-52 h-52 opacity-5" src={Mw2}/>
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Mw2}/>
              <h3 className=" group-hover:hidden md:text-sm">RESTAURANT & TRAITEUR</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="/Travaux" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="absolute top-0 left-[-20%] md:w-52 h-52 opacity-5" src={Mawaq}/>
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Mawaq} color="red"/>
              <h3 className=" group-hover:hidden md:text-sm">MAWA SHOPING </h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
          <Link to="Travaux" className="h-40 md:h-40 lg:h-60 p-2 md:p-4 relative">
            <div className="group relative overflow-hidden hover:bg-primary hover:text-white duration-500 bg-white shadow-lg shadow-gray-700/5 rounded h-full flex items-center justify-start pl-10">
              <img className="absolute top-0 left-[-20%] md:w-52 h-52 opacity-5" src={Kalado}/>
              <img className="mr-2 xl:mr-4 lg:w-16 xl:w-auto md:w-10 w-16 group-hover:scale-[2] group-hover:opacity-0 duration-200" src={Kalado} color="red"/>
              <h3 className=" group-hover:hidden md:text-sm">KALADO PRODUCTION</h3>
              <h3 className="group-hover:block hidden translate-x-[500%] group-hover:translate-x-0 group-hover:text-white duration-500 text-primary">Voir</h3>
            </div>
          </Link>
        </Slider>
      </div>
    );
  }
}