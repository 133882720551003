import React from 'react';
import imageServiceTechnique from '../../../res/restaurant/mwresto.JPG'


const Equipe = () => {
    
    return(
        <div>                 
        
        <div className='w-6/7 xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
        <div className='w-full md:h-full md:mt-2 mt-1 bg-white text-black  pb-10 md:pb-28'>
        <div className='lg:py-10 md:py-8 py'>
        <h1 className='m-50 text-blue-500  text-red-500 lg:text-xl md:text-xl text-sm text-center font-bold '>Equipe</h1>
        </div>
           <div className='w-6/6 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
        <img className="w-screen  h-auto" src= { imageServiceTechnique } alt="" />   
           </div>
       </div>         
      </div>    
   </div>  
       
    )
}

export default Equipe;