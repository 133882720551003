import React from 'react';
import imageServiceTechnique from '../../../res/images/IMG-20201126-WA0012.jpg'
import Header from '../../elements/header';
import Menu from '../../elements/menu/menu';
import Page_info from '../../elements/page_info';
import bg from "../../../res/02/Capture décran 2023-11-10 100435.png";

const About = () => {
    
    return(
        <div>          
        <Header/>
        <Menu/>
        <Page_info name={"COMPLEXE MAWA"} link={"COMPLEXE MAWA > A PROPOS"} bg={bg} textStyle={`text-white`} translate={'-translate-y-[20%]'}/>
        <div className='w-6/7 xl:px-8 flex justify-center items-center flex-col font-normal text-xs snap-center place-items-start gap-2'>
        <div className='w-full md:h-full md:mt-2 mt-1 bg-white text-black  pb-10 md:pb-28'>
        <div className='lg:py-10 md:py-8 py'>
        <h1 className='m-50 text-blue-500  text-red-500 lg:text-xl md:text-xl text-sm text-center font-bold '>QUI SOMMES NOUS?</h1>
        <h3 className='m-50 mb-10 text-red-500  text-red-500 lg:text-xl md:text-xl text-sm text-center font-bold '>Groupe Mawa</h3>
        </div>
           <div className='w-6/6 flex space-x-10 h-full justify-center items-center md:items-start md:flex-row flex-col md:px-2 px-2'>
        <img className="w-1/2 mt-44" src= { imageServiceTechnique } alt="" />   
    <div className='xl:w-[500px] lg:w-[100] md:w-[100px] w-full overflow-hidden md:px-0 px-2 flex justify-center items-center md:items-start flex-col '>
       
         <p class="text-[0.9rem] md:text-[0.9rem] text-justify font-medium leading-5 md:leading-7"><br />
         <ul class="list-disc">
             <li>
         Le <span class="text-blue-600"><a href="#">Groupe MAWA</a></span> est une structure intervenant dans le domaine de l’éducation (formation professionnelle ) , du commerce et de la production audiovisuelle. En effet,  l’offre de formation du groupe s’articule autour de quatre entités à savoir le <span class="text-blue-600"><a href="#">COMPLEXE MAWA</a></span>, le <span class="text-blue-600"><a href="#">MAWA BUSINESS INSTITUTE</a></span>, le <span class="text-blue-600"><a href="#">MAWA SANTE</a></span> et le <span class="text-blue-600"><a href="#">MAWA BULDING</a></span>. Quant à nos activités commerciales, elles s’articulent autour de <span class="text-blue-600"><a href="#">MASALIKUL JINAAN STORE</a></span> et <span class="text-blue-600"><a href="#">MAWA BOUTIQUE SHOPPING</a></span>. Nos activités de production audiovisuelle sont du ressort de <span class="text-blue-600"><a href="#">KALADO PRODUCTION</a></span>.
            </li> <li>
         Le <span class="text-blue-600"><a href="#">Groupe MAWA</a></span> comporte au total sept entités à savoir le <span class="text-blue-600"><a href="#">COMPLEXE MAWA</a></span>, le <span class="text-blue-600"><a href="#">MAWA BUSINESS INSTITUTE</a></span>, le <span class="text-blue-600"><a href="#">MAWA SANTE</a></span>, le <span class="text-blue-600"><a href="#">MAWA BULDING</a></span>, le <span class="text-blue-600"><a href="#">MASALIKUL JINAAN STORE</a></span>, le <span class="text-blue-600"><a href="#">MAWA BOUTIQUE SHOPPING</a></span> et  <span class="text-blue-600"><a href="#">KALADO PRODUCTION</a></span>.
           </li><li>
         Le <span class="text-blue-600"><a href="#">COMPLEXE MAWA</a></span> regroupe les filières de la couture, de la coiffure et de la restauration...
            </li><li>
         Le <span class="text-blue-600"><a href="#">MAWA BUSINESS INSTITUTE</a></span> regroupe quant à elle les  filières telles que transport logistique-transit, audit-comptabilité-finance, commerce international, gestion des ressources humaines, management marketing, communication-journalisme,  infographie et multimédia, marketing digital, management hôtelier, énergie renouvelable, les métiers du pétroles, la menuiserie, la mécanique automobile, agent administratif, etc
            </li><li>
         Le <span class="text-blue-600"><a href="#">MAWA SANTE</a></span> regroupant les filières telles que vendeurs en pharmacie, aide-soignant, assistant infirmier, infirmier d’Etat ou encore  sage-femme LE <span class="text-blue-600"><a href="#">MAWA BUILDING</a></span> regroupe les filières telles que génie civil, électricité bâtiment ou encore la plomberie.
            </li><li>
         Quant à nos activités commerciales, elles s’articulent autour de la vente en ligne d’articles  pour habillement et vaisselle  à travers le <span class="text-blue-600"><a href="#">MAWA BOUTIQUE SHOPPING</a></span>, de la commercialisation de mobiliers (de bureau comme de maison), d’articles électroménagers à travers <span class="text-blue-600"><a href="#">MASALIKUL JINAAN STORE</a></span>. Le showroom <span class="text-blue-600"><a href="#">MASALIKUL JINAAN STORE</a></span> est également une entité procédant à des opérations de change.
            </li><li>
         Enfin, <span class="text-blue-600"><a href="#">KALADO PRODUCTION</a></span> est dans le domaine de l’audiovisuel. D’ailleurs, elle est sur le point de présenter très prochainement sa série « GENERATION 2000  
            </li>
         </ul>

         </p>
           </div>
    </div>
         
              
     </div>
            
        </div>
        </div>
    )
}

export default About;