import React from 'react'

const Pagination = ({ totalPages, handleClick, nextPage, prevPage }) => {
  const pages = [...Array(totalPages).keys()].map(num => num+1);
  
  return (
    <div className='text-white text-sm font-normal w-full bg-gray-200 py-2 md:py-4 md:px-4'>
      <button className='text-[0.5rem] md:text-[0.8rem] font-bold px-2 md:px-4 md:py-1 mx-1 text-gray-800 hover:text-white bg-primary-light hover:bg-primary rounded' onClick={() => prevPage()}>{"<"}</button>
      { pages.map(num => (
        <button
          className='text-[0.5rem] md:text-[0.8rem] font-medium px-2 md:px-4 md:py-1 mx-1 rounded text-white bg-gray-300 hover:text-white hover:bg-primary duration-200'
          key={num}
          onClick={() => handleClick(num)}
        >{num}</button>
      )) }
      <button className='text-[0.5rem] md:text-[0.8rem] font-bold px-2 md:px-4 md:py-1 mx-1 text-gray-800 hover:text-white bg-primary-light hover:bg-primary rounded' onClick={() => nextPage()}>{">"}</button>
    </div>
  )
}

export default Pagination
