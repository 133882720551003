import React from 'react';

const users = ({ users, page }) => {
  const USER_PER_PAGE = 16
  const startIndex = ( page - 1 ) * USER_PER_PAGE;
  const selectedUsers = users.slice(startIndex, startIndex + USER_PER_PAGE);
  return (
    <div className='flex items-center justify-center flex-col mb-2 md:mb-2 min-w-[900px] md:w-full'>
      <div className='w-full flex justify-center items-center flex-col md:grid lg:grid-cols-4 md:grid-cols-3 md:gap-2'>
          {selectedUsers.map((file2, idx) => (
            <div className='bg-slate-300 h-40 md:h-60 rounded'>
              {file2.filetype==="img"?
                <img className='w-full h-full' src={file2.filepath}/>:
                <iframe className='w-full h-full' src={file2.filepath} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              }
            </div>
          ))}
      </div>
    </div>
  )
}

export default users
