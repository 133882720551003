import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NotFoundPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      navigate("#")
    }, 2000);
  }, [])
  return (
    <div>
      <div className='w-full h-screen flex justify-center items-center text-[0.6rem] md:text-[0.8rem]'>
        <div className='w-full h-full flex justify-center items-center flex-col relative p-12 md:p-40 bg-primary rounded'>
          <h1 className='font-bold text-red text-9xl mb-12'>Attention !</h1>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage